import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {deleteAlert, siteAlertSelector} from "../../store/SiteAlerts";
import {Alert, Snackbar} from "@mui/material";

export default function AlertComponent() {
    const {alerts} = useSelector(siteAlertSelector)
    const [alert, setAlert] = useState(null)

    const dispatch = useDispatch()

    useEffect(() => {
        if(alerts.length > 0) {
            setAlert(alerts[0])
            dispatch(deleteAlert())
        }
    }, [alerts, dispatch, setAlert])

    const handleClose = (_, reason) => {
        if(reason === 'clickaway') return
        setAlert(null)
    }

    return (
        <>
            {alert && (
                <Snackbar open={!!alert} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{vertical:'top', horizontal:'center'}}>
                    <Alert variant="filled" onClose={handleClose} severity={alert.type} sx={{width: '100%'}} elevation={6}>
                        {alert.message}
                    </Alert>
                </Snackbar>
            )}
        </>
    )
}