import {useDispatch, useSelector} from "react-redux";
import {getJobPositions, membersInfoSelector} from "../store/MembersInfo";
import {useEffect} from "react";

export default function useJobPositions() {
    const dispatch = useDispatch()
    const membersInfo = useSelector(membersInfoSelector)

    useEffect(() => {
        if(!membersInfo.jobPositionsLoaded) dispatch(getJobPositions())
    }, [dispatch, membersInfo])

    return membersInfo.jobPositions
}