import styles from "./Commits.module.css";
import {TableCell, TableRow} from "@mui/material";
import UserName from "../common/UserName";
import DateTimeLabel from "../common/DateTimeLabel";
import React from "react";
import CommitLink from "./CommitLink";
import CommitRepo from "./CommitRepo";
import CommitIssues, {extractIssues} from "./CommitIssues";

export default function Commit({commit, fromNow = false, showFiles = false}) {
    return (
        <TableRow className={styles.commit}>
            <TableCell align="left">
                <div className={styles.messageBlock}>
                    <CommitLink commit={commit} showFiles={showFiles} />
                </div>
            </TableCell>
            <TableCell align="center">
                <CommitIssues issues={commit.message ? extractIssues(commit.message) : []} />
            </TableCell>
            <TableCell align="center">
                <CommitRepo commit={commit} showRef={true} />
            </TableCell>
            <TableCell align="center">
                <UserName user={commit.user} defaultLabel={commit.user.displayName} />
            </TableCell>
            <TableCell align="center">
                <DateTimeLabel time={commit.timestamp} showTime={false} parentheses={false} showYear={false} fromNow={fromNow} />
            </TableCell>
        </TableRow>
    )
}