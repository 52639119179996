import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

export const addLink = createAsyncThunk("ADD_LINK", async ({url, comment}, {rejectWithValue}) => {
    try {
        const response = await axios.post("/api/v1/link", {url, comment}, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const linksInfoSlice = createSlice({
    name: "linksInfo",
    initialState: {
        links: []
    },
    extraReducers: builder =>{
        builder.addCase(addLink.fulfilled, (state, {payload}) => {

        })
    }
})

export const linksInfoSelector = state => state.linksInfo
const linksInfoReducer = linksInfoSlice.reducer
export default linksInfoReducer