import {useDispatch, useSelector} from "react-redux";
import {
    clearTaskComments,
    getTask,
    getTaskComments,
    getTasks, setTasksQuery,
    tasksInfoSelector
} from "../store/TasksInfo";
import {useEffect} from "react";
import {toast} from "react-toastify";
import {useNavigate} from "react-router";

export function useTasks(mode = "all", page = 0, count = 20, keyword = undefined) {
    const dispatch = useDispatch()
    const tasksInfo = useSelector(tasksInfoSelector)

    useEffect(() => {
        dispatch(setTasksQuery({mode, page, count, keyword}))
    }, [count, dispatch, keyword, mode, page])

    useEffect(() => {
        if(!tasksInfo.tasksLoaded && tasksInfo.mode !== undefined) {
            dispatch(getTasks())
        }
    }, [tasksInfo, dispatch])

    return {
        tasks: tasksInfo.tasks,
        total: tasksInfo.total,
        mode: tasksInfo.mode,
        page: tasksInfo.page,
        count: tasksInfo.count,
        keyword: tasksInfo.keyword,
    }
}

export function useTask(taskId) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const tasksInfo = useSelector(tasksInfoSelector)

    useEffect(() => {
        if(!taskId) return

        if(!tasksInfo.selectedTaskLoaded || (tasksInfo.selectedTask && Number(tasksInfo.selectedTask.id) !== Number(taskId))) {
            dispatch(getTask({taskId})).then((res) => {
                if(res.payload && res.payload.message) {
                    toast.error(res.payload.message)
                    navigate("/404")
                }
                dispatch(clearTaskComments())
            }).catch((e) => {
                toast.error(e)
            })
        }

        if(tasksInfo.selectedTaskLoaded && !tasksInfo.commentsLoaded){
            dispatch(getTaskComments({taskId}))
        }
    }, [tasksInfo, dispatch, taskId, navigate])

    return {
        task: (taskId && tasksInfo) ? tasksInfo.selectedTask : null,
        history: (taskId && tasksInfo) ? tasksInfo.selectedTaskHistory : [],
        comments: tasksInfo.comments
    }
}
