import dayjs from "dayjs";
import dateTimeLabelStyle from "./DateTimeLabel.module.css"

export default function DateTimeLabel({time = null, fromNow = false, color = "#000", children = null, parentheses= false, showYear = true, showTime = true, showMinute = true, ...props}) {
    let timeFormat = (showTime) ? (showYear ? "YYYY년 M월 D일 H시" : "M월 D일 H시") : (showYear ? "YYYY년 M월 D일" : "M월 D일")
    if(showTime && showMinute) timeFormat += " m분"

    let timeText = (time) ? (fromNow ? dayjs(time).fromNow() : dayjs(time).format(timeFormat)) : "-"
    const tooltip = (time && fromNow) ? dayjs(time).format("YYYY-MM-DD HH:mm") : null

    let blank = (children) ? " " + children : ""

    if(parentheses) {
        timeText = "(" + timeText + blank + ")"
    } else {
        timeText = timeText + blank
    }

    return (
        <span title={tooltip} style={{color}} className={dateTimeLabelStyle.dateTimeLabel} {...props}>{timeText}</span>
    )
}