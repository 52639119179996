import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import styles from "./Commits.module.css";

export default function Commits({children}) {
    return (
        <TableContainer className={styles.commits}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">
                            메시지
                        </TableCell>
                        <TableCell align="center">
                            이슈
                        </TableCell>
                        <TableCell align="center">
                            저장소
                        </TableCell>
                        <TableCell align="center">
                            사용자
                        </TableCell>
                        <TableCell align="center">
                            일자
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {children}
                </TableBody>
            </Table>
        </TableContainer>
    )
}