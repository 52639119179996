import {Button, Dialog, DialogActions, DialogTitle, Grid} from "@mui/material";
import {useParams} from "react-router";
import {useWikiHistory} from "../hooks/useWiki";
import {Loading} from "../App";
import {useMemo, useState} from "react";
import {WikiHistory, WikiHistoryItem} from "../components/wiki/WikiHistory";
import {useDispatch} from "react-redux";
import {deleteWikiPage} from "../store/WikiInfo";

export default function WikiHistoryPage() {
    const params = useParams()
    const [dialog, setDialog] = useState(false)

    const pageId = params["*"] || "__entry"
    const {history, loading} = useWikiHistory(pageId)
    const [rev, setRev] = useState(null)

    const dispatch = useDispatch()

    const subject = useMemo(() => {
        if(history && history.length > 0) {
            return pageId + " 수정 내역"
        } else {
            return "수정 내역"
        }
    }, [history, pageId])

    const onDelete = (pId, rev) => {
        if(pId === pageId){
            setRev(rev)
            setDialog(true)
        }
    }

    if(loading) {
        return (
            <Loading />
        )
    }

    return (
        <>
            <Grid container>
                <h1>{subject}</h1>
                <Grid item xs={12}>
                    <WikiHistory>
                        {history.map(item => (
                            <WikiHistoryItem key={item.revision} item={item} onDelete={onDelete}/>
                        ))}
                    </WikiHistory>
                </Grid>
            </Grid>
            <Dialog open={dialog} onClose={() => setDialog(false)}>
                <DialogTitle id="delete-confirm">
                    해당 버전 내용을 완전히 삭제하시겠습니까?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => {
                        dispatch(deleteWikiPage({pageId,rev,deleteFiles: true})).finally(() => {
                            setDialog(false)
                        })
                    }}>네</Button>
                    <Button onClick={() => {
                        dispatch(deleteWikiPage({pageId,rev,deleteFiles: false})).finally(() => {
                            setDialog(false)
                        })
                    }}>네, 그러나 첨부파일은 삭제하지 않습니다.</Button>
                    <Button onClick={() => setDialog(false)} autoFocus>아니요</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}