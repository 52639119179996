import useBlacklist from "../hooks/userBlacklist";
import {
    Button,
    Dialog, DialogActions, DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField
} from "@mui/material";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {addBlacklist, deleteBlacklist} from "../store/BlacklistInfo";
import {createAlert} from "../store/SiteAlerts";
import useUser from "../hooks/useUser";

export default function BlacklistPage() {
    const userInfo = useUser()
    const {roles} = userInfo
    const blacklist = useBlacklist()
    const dispatch = useDispatch()

    const [newOpen, setNewOpen] = useState(false)
    const [newEmail, setNewEmail] = useState("")
    const [deleteEmail, setDeleteEmail] = useState("")

    const handleClickNew = () => {
        setNewEmail("")
        setNewOpen(true)
    }

    const handleCloseNew = () => {
        setNewOpen(false)
        setNewEmail("")
    }

    const handleNew = () => {
        dispatch(addBlacklist({email: newEmail})).then(res => {
            if(res.error) {
                dispatch(createAlert(res.payload.message))
            } else {
                dispatch(createAlert("추가되었습니다.", "success"))
            }
        }).finally(() => {
            setNewOpen(false)
            setNewEmail("")
        })
    }

    return (
        <>
            <h1>블랙리스트 관리</h1>
            <p>여기에 추가된 이메일은 입사지원 및 Contact를 이용할 수 없습니다. 단, 직접 이메일을 보내는 것은 가능합니다.</p>

            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                이메일
                            </TableCell>
                            {roles.blacklist.d && (
                                <TableCell align="center">
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {blacklist && blacklist.length > 0 && blacklist.map((email, index) => (
                            <TableRow key={`bl_${index}`}>
                                <TableCell>
                                    {email}
                                </TableCell>
                                {roles.blacklist.d && (
                                    <TableCell align="center">
                                        <Button variant="outlined" onClick={() => {
                                            setDeleteEmail(email)
                                        }}>삭제</Button>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{marginTop: '2rem'}}>
                <Button variant="contained" onClick={handleClickNew} size="large">
                    새로운 이메일 추가
                </Button>
                <Dialog open={newOpen} onClose={handleCloseNew}>
                    <DialogTitle>새로운 이메일 추가</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Email Address"
                            type="email"
                            fullWidth
                            variant="standard"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleNew}>추가</Button>
                        <Button onClick={handleCloseNew}>취소</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={deleteEmail !== ""} onClose={() => setDeleteEmail("")}>
                    <DialogTitle id="delete-confirm">
                        정말 삭제하시겠습니까?
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => {
                            dispatch(deleteBlacklist({email: deleteEmail}))
                                .then(res => {
                                    if(res.error) {
                                        dispatch(createAlert(res.payload.message))
                                    } else {
                                        dispatch(createAlert("삭제하였습니다.", "success"))
                                    }
                                })
                                .finally(() => setDeleteEmail(""))
                        }}>네</Button>
                        <Button onClick={() => setDeleteEmail("")} autoFocus>아니요</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}