import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";


export const getVacationsHistory = createAsyncThunk("GET_VACATIONS_HISTORY", async(_, {rejectWithValue}) => {
    try {
        const response = await axios.get('/api/v1/vacations-history', {withCredentials: true})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const vacationsHistoryInfoSlice = createSlice({
    name: "vacations-history",
    initialState: {
        history: [],
        historyLoaded: false,
    },
    reducers: {

    },
    extraReducers: builder => {
        builder
            .addCase(getVacationsHistory.fulfilled, (state, {payload}) => {
                state.history = payload
                state.historyLoaded = true
            }).addCase(getVacationsHistory.rejected, state => {
                state.history = []
                state.historyLoaded = true
            })
    }
})

export const vacationsHistoryInfoSelector = state => state.vacationsHistoryInfo
const vacationsHistoryInfoReducer = vacationsHistoryInfoSlice.reducer
export default vacationsHistoryInfoReducer
