import {Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {useRef, useState} from "react";
import axios from "axios";
import {useDispatch} from "react-redux";
import {createAlert} from "../store/SiteAlerts";
import useUser from "../hooks/useUser";

const senders = [
    {
        label: "자동 이메일(No-Reply)",
        value: "no-reply@onioncrew.com",
    },
    {
        label: "일반(General Contacts)",
        value: "contact@onioncrew.com",
    },
    {
        label: "채용관련(Job Opportunity)",
        value: "job@onioncrew.com",
    }
]

export default function SendEmailPage() {
    const userInfo = useUser()
    const [mailTo, setMailTo] = useState("")
    const [subject, setSubject] = useState("")
    const [content, setContent] = useState("")
    const [receivers, setReceivers] = useState([])
    const [sender, setSender] = useState("no-reply@onioncrew.com")

    const mailToRef = useRef(null)
    const subjectRef = useRef(null)
    const contentRef = useRef(null)

    const dispatch = useDispatch()

    let lastIndex = 0

    const reset = () => {
        setMailTo("")
        setSubject("")
        setContent("")
        setReceivers([])
    }

    const handleSendMail = () => {
        let form = new FormData()

        const mailToList = mailTo.split(',').map(value => value.trim()).filter(v => v !== "")
        if(mailToList.length > 0) {
            form.append('mailto[]', mailToList)
        } else {
            dispatch(createAlert("받을 사람을 지정하세요."))
            mailToRef.current.focus()
            return
        }

        if(subject.trim() !== "") {
            form.append('subject', subject.trim())
        } else {
            dispatch(createAlert("제목이 없습니다."))
            subjectRef.current.focus()
            return
        }

        if(content.trim() !== "") {
            form.append('content', content.trim())
        } else {
            dispatch(createAlert("내용이 없습니다."))
            contentRef.current.focus()
            return
        }

        form.append('sender', sender)

        axios.post('/api/v1/sendmail', form, {withCredentials: true}).then(res => {
            if(res.status === 200) {
                dispatch(createAlert("이메일이 전송되었습니다.", "success"))
                reset()
            } else {
                if(res.error) dispatch(createAlert(res.error.message))
            }
        })
    }

    const handleBlurMailTo = () => {
        setReceivers(mailTo.split(',').map(value => value.trim()).filter(v => v !== ""))
    }

    return (
        <>
            <h1>이메일 전송</h1>
            <p>회사 공식 이메일을 전송할 수 있는 페이지입니다. 자동으로 템플릿을 이용하여 전송합니다. 이 메뉴를 사용하여 전송한 이메일은 데이터베이스에 기록이 남습니다. 장난 이메일이나 상식적으로 회사에서 보내지 않을 내용을 담지 않도록 해주시기 바랍니다.</p>
            <Box className="send-email" mt={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField required label="받을 사람" onBlur={handleBlurMailTo} fullWidth value={mailTo} onChange={(e) => {
                            setMailTo(e.target.value)
                        }} inputRef={mailToRef} />
                        {receivers.length === 0 && (<p>여러명에게 보내고 싶으면 ,(콤마)로 구분해서 작성하세요.</p>)}
                        {receivers.length !== 0 && (<p>{receivers.length}명에게 전송될 예정입니다.</p>)}
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="sendtype-label">보내는 이</InputLabel>
                            <Select required id="sendtype" label="보내는 이" value={sender} labelId="sendtype-label" displayEmpty onChange={(e) => {
                                setSender(e.target.value)
                            }} >
                                {senders.map((s, idx) => {
                                    lastIndex = idx
                                    return (
                                        <MenuItem key={idx} value={s.value}>{s.label}</MenuItem>
                                    )
                                })}
                                <MenuItem key={lastIndex + 1} value={userInfo.email}>{userInfo.displayName} ({userInfo.email})</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField required label="제목" fullWidth value={subject} onChange={(e) => {
                            setSubject(e.target.value)
                        }} inputRef={subjectRef}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField required label="내용" multiline={true} fullWidth rows={12} value={content} onChange={(e) => {
                            setContent(e.target.value)
                        }} inputRef={contentRef}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" size="large" onClick={handleSendMail}>보내기</Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}