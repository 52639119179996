import {useDispatch, useSelector} from "react-redux";
import {getExtraInfo, userInfoSelector, validateToken} from "../store/UserInfo";
import useEffectOnce from "./useEffectOnce";
import {toast} from "react-toastify";
import {createContext, useContext} from "react";

const AuthContext = createContext(null)

export function AuthProvider({children}) {
    const dispatch = useDispatch()
    const userInfo = useSelector(userInfoSelector)

    useEffectOnce(() => {
        if(!userInfo.logged && !userInfo.loginTried)
            dispatch(validateToken()).then(res => {
                if(!res.error) {
                    dispatch(getExtraInfo())
                } else {
                    toast.error(res.error)
                }
            })
    })

    return (
        <AuthContext.Provider value={{
            logged: userInfo.logged,
            id: userInfo.id,
            email: userInfo.email,
            displayName: userInfo.displayName,
            status: userInfo.status,
            roles: userInfo.roles,
            extra: userInfo.extra,
            loginTried: userInfo.loginTried
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default function useUser() {
    return useContext(AuthContext)
}