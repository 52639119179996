import styles from "./Commits.module.css"

/*
feat: 새로운 기능 추가
fix: 수정사항이 포함됨
refactor: 코드 리팩토링
docs: 문서 추가/수정
test: 테스트 코드 추가/수정
res: 리소스 추가/수정
 */

export default function CommitAction({action}) {
    let actionClass

    if(!action || action === "") return null

    switch(action) {
    case "fix":
        actionClass = styles.actionFix
        break
    case "refactor":
        actionClass = styles.actionRefactor
        break
    case "docs":
        actionClass = styles.actionDocs
        break
    case "test":
        actionClass = styles.actionTest
        break
    case "res":
        actionClass = styles.actionRes
        break
    default:
        actionClass = styles.actionFeat
        break
    }

    return (
        <span className={`${styles.action} ${actionClass}`}>
            {action}
        </span>
    )
}