import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

export const validateToken = createAsyncThunk("VALIDATE_TOKEN", async(_, {rejectWithValue}) => {
    try {
        const response = await axios.get(`/auth/validate`, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const getExtraInfo = createAsyncThunk("GET_EXTRA_INFO", async(_, {rejectWithValue}) => {
    try {
        const response = await axios.get(`/api/v1/member`, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

const defaultRoles = {
    jobs: {v: false, e: false, d: false},
    applicants: {v: false, e: false, d: false},
    blacklist: {v: false, e: false, d: false},
    users: {v: false, e: false, d: false},
    comments: {v: false, e: false, d:false},
    forums: {v: false, e: false, d: false},
    wiki: {v: false, e: false, d: false},
}

export const userInfoSlice = createSlice({
    name: "userInfo",
    initialState: {
        logged: false,
        id: 0,
        displayName: "",
        email: "",
        status: 0,
        roles: defaultRoles,
        extra: {},
        extraLoaded: false,
        loginTried: false,
    },
    reducers: {
        logout: (state) => {
            state.logged = false
            state.id = 0
            state.email = ""
            state.displayName = ""
            state.status = 0
            state.roles = defaultRoles
            state.loginTried = true
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getExtraInfo.pending, state => {
                state.loginTrying = true
            })
            .addCase(validateToken.fulfilled, (state, {payload}) => {
                state.logged = true
                state.id = payload.id
                state.email = payload.email
                state.displayName = payload.displayName
                state.status = payload.status
                state.roles = payload.roles
                state.loginTried = true
            })
            .addCase(validateToken.rejected, (state) => {
                state.logged = false
                state.id = 0
                state.email = ""
                state.displayName = ""
                state.status = 0
                state.roles = defaultRoles
                state.loginTried = true
            })
            .addCase(getExtraInfo.fulfilled, (state, {payload}) => {
                state.extra = payload
                state.extraLoaded = true
            })
            .addCase(getExtraInfo.rejected, (state) => {
                state.extraLoaded = true
            })
    }
})

export const userInfoSelector = state => state.user
export const {logout} = userInfoSlice.actions

const userInfoReducer = userInfoSlice.reducer
export default userInfoReducer