import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getMembers, membersInfoSelector} from "../store/MembersInfo";

export default function useMembers() {
    const dispatch = useDispatch()
    const membersInfo = useSelector(membersInfoSelector)

    useEffect(() => {
        if(!membersInfo.usersLoaded) dispatch(getMembers())
    }, [dispatch, membersInfo])

    return membersInfo.users
}