import dropzoneStyles from "./FileDropzone.module.css";
import {bytesToSize} from "../util";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import {useState} from "react";
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css";
import fileDownload from "js-file-download";

function isImage(file) {
    return file.contentType.split('/')[0] === 'image';
}

function FilePreviewIcon({file}) {
    if (!file) return null

    if(isImage(file)) {
        return (
            <div className={dropzoneStyles.icon}>
                <img src={file.path} alt={file.fileName}/>
            </div>
        )
    } else {
        return (
            <div className={dropzoneStyles.icon}>
                <FilePresentIcon sx={{width:32, height:32}}/>
            </div>
        )
    }
}

function FilePreview({file, onDelete, viewMode, onClick = null}) {
    if(!file) return null

    const fileSize = bytesToSize(file.size)

    return (
        <div className={dropzoneStyles.thumbnail}>
            <div className={dropzoneStyles.clickArea} onClick={onClick}>
                <FilePreviewIcon file={file} />
                <div>
                    {file.fileName} ({fileSize})
                </div>
            </div>
            {!viewMode && (
                <Button onClick={() => onDelete(file)} size="small" sx={{minWidth: 32}}>
                    <DeleteIcon sx={{width: "20px"}}/>
                </Button>
            )}
        </div>
    )
}

export default function UploadedFiles({files, onDelete = null, viewMode = false}) {
    const [targetFile, setTargetFile] = useState(null)
    const [confirmOpen, setConfirmOpen] = useState(false)

    const [lightboxOpen, setLightboxOpen] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)

    const onLightboxClose = () => {
        setLightboxOpen(false)
        setSelectedImage(null)
    }
    const handleImageClick = (file) => {
        if(isImage(file)) {
            setSelectedImage(file.path)
            setLightboxOpen(true)
        } else {
            // Download
            if(file.path && file.fileName) fileDownload(file.path, file.fileName)
        }
    }

    const handleDeleteFile = () => {
        if(!viewMode && onDelete) onDelete(targetFile)
        setConfirmOpen(false)
    }

    const handleDialogClose = () => {
        setConfirmOpen(false)
    }

    return (
        <>
            <aside className={dropzoneStyles.preview}>
                {files.map((file, idx) => (
                    <FilePreview key={idx}
                                 file={file}
                                 viewMode={viewMode}
                                 onClick={() => handleImageClick(file)}
                                 onDelete={(f) => {
                                    if(!viewMode) {
                                        setTargetFile(f)
                                        setConfirmOpen(true)
                                    }
                    }}>
                        {file.fileName}
                    </FilePreview>
                ))}
            </aside>
            <Dialog onClose={handleDialogClose} open={confirmOpen}>
                <DialogTitle>확인</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        이 파일을 삭제합니까? 이 작업은 즉시 실행되며 취소할 수 없습니다.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteFile}>확인</Button>
                    <Button onClick={handleDialogClose}>취소</Button>
                </DialogActions>
            </Dialog>
            {lightboxOpen && (
                <Lightbox mainSrc={selectedImage} onCloseRequest={onLightboxClose} />
            )}
        </>
    )
}

