import tasksStyle from "./Tasks.module.css"
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

export default function Tasks({children, showId = true}) {
    return (
        <TableContainer className={tasksStyle.list}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {showId && (
                            <TableCell align="center">#</TableCell>
                        )}
                        <TableCell align="center">
                            제목
                        </TableCell>
                        <TableCell align="center">
                            상태
                        </TableCell>
                        <TableCell align="center">
                            담당자
                        </TableCell>
                        <TableCell align="center">
                            시작일
                        </TableCell>
                        <TableCell align="center">
                            예상완료일
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {children}
                </TableBody>
            </Table>
        </TableContainer>
    )
}