import {useDispatch, useSelector} from "react-redux";
import {forumInfoSelector, getForumInfo} from "../store/ForumInfo";
import {useEffect} from "react";


export default function useForum(forumId) {
    const dispatch = useDispatch()
    const forumInfo = useSelector(forumInfoSelector)
    
    useEffect(() => {
        if(forumId) {
            if (!forumInfo.infoLoaded) {
                dispatch(getForumInfo({forumId}))
            }
        }
    }, [dispatch, forumId, forumInfo])

    return forumInfo
}