import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

export const getMembers = createAsyncThunk("GET_MEMBERS", async (_, {rejectWithValue, requestId, getState}) => {
    try {
        const {usersRequestId} = getState().membersInfo
        if(usersRequestId !== null && usersRequestId !== requestId) return

        const response = await axios.get('/api/v1/members', {withCredentials: true})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const updateMemberRole = createAsyncThunk("UPDATE_MEMBER_ROLE", async ({id, roles}, {rejectWithValue}) => {
    try {
        const response = await axios.post('/api/v1/member/role', {id, roles}, {withCredentials: true})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const addMember = createAsyncThunk("REGISTER_MEMBER", async ({email}, {rejectWithValue}) => {
    try {
        const response = await axios.post('/api/v1/member/new', {email}, {withCredentials: true})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const deleteMember = createAsyncThunk("DELETE_MEMBER", async ({id}, {rejectWithValue}) => {
    try {
        const response = await axios.delete(`/api/v1/member/${id}`, {withCredentials: true})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const getJobPositions = createAsyncThunk("GET_JOBPOSITIONS", async(_, {rejectWithValue, getState, requestId}) => {
    try {
        const {jobPositionsRequestId} = getState().membersInfo
        if(jobPositionsRequestId !== null && jobPositionsRequestId !== requestId) return

        const response = await axios.get('/api/v1/member/positions', {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const addJobPosition = createAsyncThunk("ADD_JOBPOSITION", async({ko, en}, {rejectWithValue}) => {
    try {
        const response = await axios.post('/api/v1/member/position', {ko, en}, {withCredentials: true})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const deleteJobPosition = createAsyncThunk("DELETE_JOBPOSITION", async({id}, {rejectWithValue}) => {
    try {
        const response = await axios.delete(`/api/v1/member/position/${id}`, {withCredentials: true})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const membersSlice = createSlice({
    name: "members",
    initialState: {
        users: [],
        usersLoaded: false,
        usersRequestId: null,
        error: null,
        jobPositionsRequestId: null,
        jobPositions: [],
        jobPositionsLoaded: false
    },
    reducers: {
        clearMembers: (state) => {
            state.usersLoaded = false
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getMembers.fulfilled, (state, {payload, meta}) => {
                if(state.usersRequestId && state.usersRequestId === meta.requestId) {
                    state.users = payload
                    state.usersLoaded = true
                    state.usersRequestId = null
                }
            })
            .addCase(getMembers.rejected, (state, {meta}) => {
                if(state.usersRequestId && state.usersRequestId === meta.requestId) {
                    state.users = []
                    state.usersLoaded = true
                    state.usersRequestId = null
                }
            })
            .addCase(getMembers.pending, (state, {meta}) => {
                if(!state.usersRequestId) state.usersRequestId = meta.requestId
            })
            .addCase(updateMemberRole.fulfilled, (state, {payload}) => {
                let users = state.users.filter((u) => Number(u.id) !== Number(payload.id))
                users.push(payload)
                users.sort((a, b) => a.id - b.id)
                state.users = users
            })
            .addCase(updateMemberRole.rejected, (state, actions) => {
                if(actions.error) state.error = actions.error
            })
            .addCase(addMember.fulfilled, (state, {payload}) => {
                let users = state.users.filter((u) => Number(u.id) !== Number(payload.id))
                users.push(payload)
                users.sort((a, b) => a.id - b.id)
                state.users = users
            })
            .addCase(deleteMember.fulfilled, (state, {payload}) => {
                state.users = state.users.filter(u => Number(u.id) !== Number(payload.id))
            })
            .addCase(getJobPositions.fulfilled, (state, {payload, meta}) => {
                if(state.jobPositionsRequestId && state.jobPositionsRequestId === meta.requestId) {
                    state.jobPositions = payload
                    state.jobPositionsLoaded = true
                    state.jobPositionsRequestId = null
                }
            })
            .addCase(getJobPositions.rejected, (state, {meta}) => {
                if(state.jobPositionsRequestId && state.jobPositionsRequestId === meta.requestId) {
                    state.jobPositions = []
                    state.jobPositionsLoaded = true
                    state.jobPositionsRequestId = null
                }
            })
            .addCase(getJobPositions.pending, (state, {meta}) => {
                const {requestId} = meta
                if(!state.jobPositionsRequestId) state.jobPositionsRequestId = requestId
            })
            .addCase(addJobPosition.fulfilled, (state, {payload}) => {
                state.jobPositions.push(payload)
                sortJobPositions(state)
            })
            .addCase(deleteJobPosition.fulfilled, (state, {payload}) => {
                state.jobPositions = state.jobPositions.filter(position => Number(position.id) !== Number(payload.id))
            })
    }
})

function sortJobPositions(state) {
    state.jobPositions.sort(function(a, b) {
        return b.id - a.id
    })
}

export const membersInfoSelector = state => state.membersInfo
const membersInfoReducer = membersSlice.reducer
export const {clearMembers} = membersSlice.actions
export default membersInfoReducer