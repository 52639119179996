import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField
} from "@mui/material";
import useJobPositions from "../hooks/useJobPositions";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {addJobPosition, deleteJobPosition} from "../store/MembersInfo";
import {useNavigate} from "react-router";
import useUser from "../hooks/useUser";

export default function PositionsPage() {
    const userInfo = useUser()
    const positions = useJobPositions()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [newDialogOpen, setNewDialogOpen] = useState(false)
    const [newKoEntry, setNewKoEntry] = useState("")
    const [newEnEntry, setNewEnEntry] = useState("")

    const [deleteId, setDeleteId] = useState(-1)

    const handleCloseNewDialog = () => {
        setNewDialogOpen(false)
        setNewKoEntry("")
        setNewEnEntry("")
    }

    const handleNewEntry = () => {
        if(!newKoEntry || !newEnEntry || newKoEntry.trim() === "" || newEnEntry.trim() === "") return
        dispatch(addJobPosition({ko: newKoEntry, en: newEnEntry}))
            .finally(() => {
                handleCloseNewDialog()
            })
    }

    if(!userInfo.roles.users.e) return null

    return (
        <>
            <h1>직급 목록</h1>
            <TableContainer>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">
                            한글명
                        </TableCell>
                        <TableCell align="center">
                            영문명
                        </TableCell>
                        <TableCell align="center" width="160px">
                            #
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {positions && positions.map(position => (
                        <TableRow key={position.id}>
                            <TableCell>
                                {position.ko}
                            </TableCell>
                            <TableCell>
                                {position.en}
                            </TableCell>
                            <TableCell align="center">
                                <Button onClick={() => setDeleteId(position.id)}>삭제</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            </TableContainer>
            <Grid container mt={3} spacing={2}>
                <Grid item>
                    <Button size="large" variant="contained" onClick={() => navigate(-1)}>
                        뒤로
                    </Button>
                </Grid>
                <Grid item>
                    <Button size="large" variant="contained" onClick={() => setNewDialogOpen(true)}>
                        추가
                    </Button>
                    <Dialog open={newDialogOpen} onClose={() => handleCloseNewDialog()}>
                        <DialogTitle>새로운 직급 추가</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                새로운 직급명을 입력하세요.
                            </DialogContentText>
                            <TextField autoFocus margin="dense" id="koPosition" label="한글 명칭" type="koPosition" fullWidth variant="standard" value={newKoEntry} onChange={(e) => setNewKoEntry(e.target.value)} />
                            <TextField margin="dense" id="enPosition" label="영문 명칭" type="enPosition" fullWidth variant="standard" value={newEnEntry} onChange={(e) => setNewEnEntry(e.target.value)} />
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={newKoEntry.trim() === "" || newEnEntry.trim() === ""} onClick={() => handleNewEntry()}>추가</Button>
                            <Button onClick={() => handleCloseNewDialog()}>취소</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={deleteId !== -1} onClose={() => setDeleteId(-1)}>
                        <DialogTitle id="delete-confirm">
                            정말 삭제하시겠습니까?
                        </DialogTitle>
                        <DialogActions>
                            <Button onClick={() => {
                                dispatch(deleteJobPosition({id: deleteId})).finally(() => setDeleteId(-1))
                            }}>네</Button>
                            <Button onClick={() => setDeleteId(-1)} autoFocus>아니요</Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Grid>
        </>
    )
}