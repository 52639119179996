const menuList = {
    logged: [
        {
            id: "tasks",
            label: "작업",
            to: "/tasks"
        },
        {
            id: "commits",
            label: "커밋",
            to: "/commits"
        },
        // {
        //     id: "diagrams",
        //     label: "다이어그램",
        //     to: "/diagrams"
        // },
        {
            id: "wiki",
            label: "위키",
            to: "/wiki"
        },
        "-",
        {
            id: "vacation",
            label: "휴가",
            to: "/vacation"
        },
        {
            id: "vacations-history",
            label: "직원 휴가 열람",
            to: "/vacations-history"
        },
        {
            id: "certificate",
            label: "증명서 발급",
            to: "/certs",
        },
        {
            id: "approval",
            label: "결재",
            to: "/approval"
        },
        "-",
        {
            id: "jobs",
            label: "채용공고",
            to: "/jobs",
            required: "jobs"
        },
        {
            id: "applicants",
            label: "지원자",
            to: "/applicants",
            required: "applicants"
        },
        {
            id: "sendmail",
            label: "메일전송",
            to: "/sendmail",
        },
        "-",
        {
            id: "blacklist",
            label: "블랙리스트 관리",
            to: "/blacklist",
            required: "blacklist"
        },
        {
            id: "users",
            label: "승선자 목록",
            to: "/users",
            required: "users"
        },
        "-",
        {
            id: "signature",
            label: "서명 유틸리티",
            to: "/signature"
        },
        {
            id: "invitation",
            label: "에어로넷 초대장",
            to: "/aeronet",
        },
        {
            id: "setup",
            label: "계정 설정",
            to: "/setup"
        },
    ],
    not_logged: [
        {
            id: "login",
            label: "로그인",
            href: `${process.env.REACT_APP_API_SERVER}/auth/login`,
        }
    ]
}

export default menuList