import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

export const getDiagrams = createAsyncThunk("DIAGRAMS/LIST", async (_, {rejectWithValue, requestId, getState}) => {
    try {
        const {diagramsRequestId, page, count, keyword} = getState().diagramsInfo
        if(diagramsRequestId && diagramsRequestId !== requestId) return

        const response = await axios.get(`/api/v1/diagrams?page=${page}&count=${count}&keyword=${keyword}`, {
            withCredentials: true,
            params: (keyword) ? {page, count, keyword} : {page, count, keyword:""}
        })
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const postDiagram = createAsyncThunk("DIAGRAM/POST", async ({formData}, {rejectWithValue}) => {
    try {
        const response = await axios.post("/api/v1/diagram", formData, {withCredentials: true, headers: {
            "Content-Type": "multipart/form-data"
            }})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const getDiagram = createAsyncThunk("DIAGRAM/GET", async ({id}, {rejectWithValue}) => {
    try {
        const response = await axios.get(`/api/v1/diagram/${id}`, {withCredentials: true})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const deleteDiagram = createAsyncThunk("DIAGRAM/DELETE", async ({id}, {rejectWithValue}) => {
    try {
        const response = await axios.delete(`/api/v1/diagram/${id}`, {withCredentials: true})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const diagramsInfoSlice = createSlice({
    name: "diagramsInfo",
    initialState: {
        diagrams: [],
        diagramsLoaded: false,
        selectedDiagram: null,
        selectedDiagramLoaded: false,
        page: 0,
        count: 20,
        keyword: undefined,
        total: 0,
        diagramsRequestId: null,
    },
    reducers: {
        setDiagramsQuery: (state, action) => {
            let {page, count, keyword} = action.payload

            if(count && typeof count === "string") count = Number(count)
            if(page && typeof page === "string") page = Number(page)

            let refreshRequired = false

            if(page !== undefined && page !== state.page) {
                state.page = page
                refreshRequired = true
            }
            if(count !== undefined && count !== state.count) {
                state.count = count
                refreshRequired = true
            }
            if(keyword === null) keyword = undefined
            if(keyword !== state.keyword) {
                state.keyword = keyword
                refreshRequired = true
            }

            if(refreshRequired) {
                state.diagrams = []
                state.total = 0
                state.diagramsLoaded = false
            }
        },
        clearDiagrams: (state) => {
            state.diagrams = []
            state.total = 0
            state.diagramsLoaded = false
        },
        clearDiagram: (state) => {
            state.selectedDiagram = null
            state.selectedDiagramLoaded = false
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getDiagrams.fulfilled, (state, {payload, meta}) => {
                const {requestId} = meta
                if(state.diagramsRequestId && state.diagramsRequestId === requestId) {
                    state.diagrams = payload.diagrams
                    state.total = payload.total
                    state.diagramsLoaded = true
                    state.diagramsRequestId = null
                }
            })
            .addCase(getDiagrams.rejected, (state, {meta}) => {
                if(state.diagramsRequestId && state.diagramsRequestId === meta.requestId) {
                    state.diagrams = []
                    state.total = 0
                    state.diagramsLoaded = true
                    state.diagramsRequestId = null
                }
            })
            .addCase(getDiagrams.pending, (state, {meta}) => {
                if(!state.diagramsRequestId) {
                    state.diagramsRequestId = meta.requestId
                }
            })
            .addCase(getDiagram.fulfilled, (state, {payload}) => {
                state.selectedDiagram = payload["diagram"]
                state.selectedDiagramLoaded = true
            })
            .addCase(getDiagram.rejected, (state) => {
                state.selectedDiagram = null
                state.selectedDiagramLoaded = true
            })
            .addCase(postDiagram.fulfilled, (state, {payload}) => {
                state.diagramsLoaded = false
                if(state.selectedDiagram && state.selectedDiagram.id === payload["diagram"].id) {
                    state.selectedDiagram = payload["diagram"]
                    state.selectedDiagramLoaded = true
                }
            })
            .addCase(deleteDiagram.fulfilled, (state, {payload}) => {
                state.diagramsLoaded = false
                if(state.selectedDiagram && state.selectedDiagram.id === payload.id) {
                    state.selectedDiagram = null
                    state.selectedDiagramLoaded = false
                }
            })
    }
})

export const diagramsInfoSelector = state => state.diagramsInfo
const diagramsInfoReducer = diagramsInfoSlice.reducer
export const {setDiagramsQuery, clearDiagram, clearDiagrams} = diagramsInfoSlice.actions
export default diagramsInfoReducer