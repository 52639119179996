import {Link} from "react-router-dom";
import styles from "./CommitIssues.module.css"

export function extractIssues(commitMessage) {
    const regex = /#(\d+)/g
    const matches = commitMessage.matchAll(regex)

    return Array.from(matches, match => match[1])
}

export default function CommitIssues({issues}) {
    if(!issues || !Array.isArray(issues) || issues.length === 0) return null

    return (
        <span className={styles.issues}>
            {issues.map(issue => (
                <Link className={styles.issue} key={issue} to={`/task/${issue}`}>#{issue}</Link>
            ))}
        </span>
    )
}
