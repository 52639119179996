import {useDispatch, useSelector} from "react-redux";
import {getSiteDataInfo, siteDataInfoSelector} from "../store/SiteDataInfo";
import {useEffect} from "react";

export default function useSiteData() {
    const dispatch = useDispatch()
    const siteDataInfo = useSelector(siteDataInfoSelector)

    useEffect(() => {
        if(!siteDataInfo.siteDataLoaded) {
            dispatch(getSiteDataInfo())
        }
    }, [dispatch, siteDataInfo.siteDataLoaded])

    return {
        vacations: siteDataInfo.vacations,
        approvals: siteDataInfo.approvals,
        birthdays: siteDataInfo.birthdays,
        newCrews: siteDataInfo.newCrews,
        events: siteDataInfo.events,
        tasks: siteDataInfo.tasks,
        wiki: siteDataInfo.wiki,
        commits: siteDataInfo.commits,
    }
}