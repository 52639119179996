import {taskCategory} from "../../store/TasksInfo";
import taskCategoryStyles from "./TaskCategory.module.css";


export default function TaskCategory({category, status = 0, short = false}) {
    const style = (status === 1) ? {} : {backgroundColor: taskCategory[category].bg}

    return (
        <span style={style} className={taskCategoryStyles.label}>
            {short ? taskCategory[category].short: taskCategory[category].label}
        </span>
    )
}
