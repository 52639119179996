import {useMemo, useRef, useState} from "react";
import {Box, Button, Chip, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {useDispatch} from "react-redux";

import axios from "axios";
import {clearMembers} from "../../store/MembersInfo";
import useJobPositions from "../../hooks/useJobPositions";
import MemberSelector from "../common/MemberSelector";
import useOnceCall from "../../hooks/useOnceCall";
import PostCodeInput from "../common/PostCodeInput";
import {createAlert} from "../../store/SiteAlerts";
import {statusLabels} from "../../utils/labels";

export default function UserExtraInfoEditor({userId, closeButton, onClose, onSaved, userInfo}) {
    const [displayName, setDisplayName] = useState("")
    const [startDate, setStartDate] = useState(new Date())
    const [postalOpen, setPostalOpen] = useState(false)
    const [address, setAddress] = useState("")
    const [postalCode, setPostalCode] = useState("")
    const [phone, setPhone] = useState("")
    const [extraAddress, setExtraAddress] = useState("")
    const [position, setPosition] = useState(0)
    const [work, setWork] = useState("")
    const [supervisorId, setSupervisorId] = useState(0)
    const [birthDate, setBirthDate] = useState(new Date())
    const [status, setStatus] = useState(0)
    const [additionalEmails, setAdditionalEmails] = useState([])
    const [newEmail, setNewEmail] = useState('')

    const jobEditable = useMemo(() => !!(userInfo && userInfo.roles.users.e), [userInfo])

    const extraAddressRef = useRef(null)
    const dispatch = useDispatch()

    const jobPositions = useJobPositions()

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    const handleChange = (newValue) => {
        setStartDate(newValue)
    }

    const handleBirthDateChange = (newValue) => {
        setBirthDate(newValue)
    }

    const deleteEmail = email => {
        axios.delete(`/api/v1/member/email/${userId}`, {data: {email}, withCredentials: true}).then(res => {
            if(res.status === 200) {
                setAdditionalEmails(additionalEmails.filter(e => e !== res.data.email))
            }
            setNewEmail('')
        })
    }

    const addEmail = email => {
        axios.post(`/api/v1/member/email/${userId}`, {email}, {withCredentials: true}).then(res => {
            if(res.status === 200) {
                setAdditionalEmails(current => [...current, email])
            }
        })
    }

    useOnceCall(() => {
        if(userId > 0) {
            axios.get(`/api/v1/member/${userId}`, {withCredentials: true}).then(res => {
                if (res.status === 200) {
                    setDisplayName(res.data.displayName)
                    setStartDate(res.data.started)
                    setPhone(res.data.phone)
                    setAddress(res.data.address)
                    setPostalCode(res.data.postalCode)
                    setExtraAddress(res.data.extraAddress)
                    setPosition(res.data.position)
                    setSupervisorId(res.data.supervisor)
                    setBirthDate(res.data.birth)
                    setWork(res.data.work)
                    setStatus(res.data.status)
                    setAdditionalEmails(res.data.additionalEmails)
                }
            })
        }
        if(jobPositions && jobPositions.length > 0){
            setPosition(jobPositions[0].id)
        }
    }, userId > 0 && jobPositions && jobPositions.length > 0)

    return (
        <>
            <PostCodeInput open={postalOpen} onClose={() => setPostalOpen(false)} onComplete={(data) => {
                setAddress(data.address)
                setPostalCode(data.zonecode)
            }} />
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name="displayName"
                            required
                            fullWidth
                            id="displayName"
                            label="이름"
                            value={displayName}
                            autoFocus={true}
                            onChange={(e) => setDisplayName(e.target.value)}
                        />
                    </Grid>
                    {userInfo.roles.users.e && (
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="supervisor-label">슈퍼바이저</InputLabel>
                                <MemberSelector fullWidth
                                                activeOnly={true}
                                                userInfo={userInfo}
                                                value={supervisorId}
                                                onChange={(e) => setSupervisorId(e.target.value)}
                                                labelId="supervisor-label"
                                                label="슈퍼바이저"
                                                id="supervisor-selector"
                                />
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        <DatePicker
                            disableMaskedInput={true}
                            label="입사일"
                            onChange={handleChange}
                            value={startDate}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DatePicker
                            disableMaskedInput={true}
                            label="생일"
                            onChange={handleBirthDateChange}
                            value={birthDate}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="phoneNumber"
                            fullWidth
                            id="phoneNumber"
                            label="전화번호"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="position-label">직급</InputLabel>
                            <Select disabled={!jobEditable} required id="position" label="직급" value={position} labelId="position-label" displayEmpty onChange={(e) => {
                                setPosition(e.target.value)
                            }}>
                                <MenuItem key={0} value={0}>미정</MenuItem>
                                {jobPositions.map((position) => (
                                    <MenuItem key={position.id} value={position.id}>{position.ko}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled={!jobEditable}
                            name="work"
                            fullWidth
                            id="work"
                            label="업무"
                            value={work}
                            onChange={(e) => setWork(e.target.value)}
                        />
                    </Grid>
                    {userInfo.roles.users.e && (
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="status-label">상태</InputLabel>
                                <Select disabled={!jobEditable} onChange={e => setStatus(e.target.value)} value={status} labelId="status-label" label="상태">
                                    {statusLabels.map((s, index) =>
                                        <MenuItem key={index} value={index}>
                                            {s}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={6}>
                                <TextField
                                    name="address"
                                    id="address"
                                    label="집 주소"
                                    disabled
                                    fullWidth
                                    value={address}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    name="postal"
                                    id="postal"
                                    label="우편번호"
                                    disabled
                                    fullWidth
                                    value={postalCode}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Button fullWidth variant="text" size="large" onClick={() => {
                                    setPostalOpen(true)
                                }}>우편번호</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="extraAddress"
                            id="extraAddress"
                            label="나머지 주소"
                            fullWidth
                            value={extraAddress}
                            ref={extraAddressRef}
                            onChange={(e) => setExtraAddress(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="newEmail"
                            id="newEmail"
                            label="추가 이메일"
                            fullWidth
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                            onKeyDown={e => {if(e.keyCode === 13) {
                                e.preventDefault()
                                addEmail(newEmail)
                                setNewEmail('')
                                return false
                            }}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {additionalEmails.map(email => {
                            return (
                                <Chip color="primary" sx={{margin: "4px"}} key={email} label={email} variant="outlined" onDelete={() => { deleteEmail(email) }} />
                            )
                        })}
                    </Grid>
                    <Grid item xs={6} mt={3}>
                        <Button variant="contained" size="large" onClick={() => {
                            if (userId > 0) {
                                axios.post('/api/v1/member/extra', {
                                    id: userId,
                                    displayName: displayName,
                                    phone: phone,
                                    address: address,
                                    extraAddress: extraAddress,
                                    postalCode: postalCode,
                                    started: startDate,
                                    position: position,
                                    supervisor: supervisorId,
                                    birth: birthDate,
                                    work: work,
                                    status: status,
                                }, {withCredentials: true}).then(res => {
                                    if(res.error) {
                                        dispatch(createAlert(res.payload.message))
                                    } else {
                                        dispatch(createAlert("업데이트 완료", "success"))
                                        dispatch(clearMembers())
                                    }
                                }).finally(() => {
                                    if (onSaved) onSaved()
                                })
                            }
                        }}>저장하기</Button>
                    </Grid>
                    <Grid item xs={6} mt={3} textAlign="right">
                        {closeButton && (
                            <>
                                <Button variant="contained" size="large" onClick={onClose}>
                                    닫기
                                </Button>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}