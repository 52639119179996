import {useSelector} from "react-redux";
import {userInfoSelector} from "../store/UserInfo";
import {Box, AppBar, Toolbar, Typography, Link, IconButton} from "@mui/material";
import {useNavigate} from "react-router";
import SettingsIcon from '@mui/icons-material/Settings';
import SignInButton from "../components/common/SignInButton";
import SignOutButton from "../components/common/SignOutButton";
import MenuIcon from '@mui/icons-material/Menu';

function Header({toggleDrawer}) {
    const navigate = useNavigate()
    const userInfo = useSelector(userInfoSelector)

    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="Menu"
                    onClick={toggleDrawer}
                    sx={{
                        marginRight: '12px',
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    fontWeight="700"
                    sx={{ mr: 2, display: 'flex' }}
                >
                    <Link onClick={() => navigate('/')} style={{color: '#fff', cursor: 'pointer'}} underline='none'>
                        Kitchen
                    </Link>
                </Typography>
                <Box sx={{ flexGrow: 1, display: 'flex', marginLeft: '1rem', justifyContent: "flex-end" }}>
                    {userInfo.logged ?
                        <>
                            <IconButton color="inherit" aria-label="Settings" onClick={() => {
                                navigate('/setup')
                            }}>
                                <SettingsIcon />
                            </IconButton>
                            <SignOutButton />
                        </> : <SignInButton />}
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default Header;