import {useLocation, useNavigate, useParams} from "react-router";
import {Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import useForum from "../hooks/useForum";

function NewPostButton({pathname, navigate}) {
    return (
        <Button variant="contained" size="large" onClick={() => {
            navigate(pathname + "/post")
        }}>새로운 글 작성</Button>
    )
}

export default function Forum() {
    const {forumId} = useParams()
    const {pathname} = useLocation()
    const navigate = useNavigate()

    const forumData = useForum(forumId)
    if(!forumData) return null

    return (
        <>
            <h1>{forumData.title}</h1>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <NewPostButton navigate={navigate} pathname={pathname} />
                </Grid>
                <Grid item xs={6} textAlign="right">
                    <Button><SettingsIcon /></Button>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" width={80}>#</TableCell>
                                    <TableCell align="center" width={240}>카테고리</TableCell>
                                    <TableCell align="center">제목</TableCell>
                                    <TableCell width={120} align="center">작성자</TableCell>
                                    <TableCell width={120} align="center">작성일</TableCell>
                                    <TableCell width={100} align="center">조회수</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {forumData.posts && (forumData.posts.map(post => (
                                    <TableRow>
                                        <TableCell>{post.id}</TableCell>
                                        <TableCell>{post.categoryTitle}</TableCell>
                                        <TableCell>{post.subject}</TableCell>
                                        <TableCell>{post.authorName}</TableCell>
                                        <TableCell>{post.created}</TableCell>
                                        <TableCell>{post.viewCount}</TableCell>
                                    </TableRow>
                                )))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12}>
                    <NewPostButton navigate={navigate} pathname={pathname} />
                </Grid>
            </Grid>
        </>
    )
}