import {useDispatch, useSelector} from "react-redux";
import {
    approvalsInfoSelector,
    getApprovals, setApprovalsQuery,
} from "../store/ApprovalsInfo";
import {useEffect} from "react";

export default function useApprovals(userId, page, count) {
    const dispatch = useDispatch()
    const approvalsInfo = useSelector(approvalsInfoSelector)

    useEffect(() => {
        if(userId && userId > 0 && (!approvalsInfo.approvalsLoaded)) {
            dispatch(getApprovals({id: userId}))
        }
    }, [approvalsInfo, dispatch, userId])

    useEffect(() => {
        dispatch(setApprovalsQuery({page, count}))
    }, [approvalsInfo, page, count, dispatch])

    return {
        approvals: approvalsInfo.approvals,
        total: approvalsInfo.total
    }
}