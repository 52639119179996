import {Container, Link} from "@mui/material";

function NonAuthorizedPage() {
    return (
        <Container>
            <h1>로그인이 필요합니다.</h1>
            <p>이 웹사이트는 로그인을 필요로 합니다. 이 사이트는 내부 용도로만 사용되므로 만일 어니언크루에 대해 알고 싶으시다면 공식 웹사이트를 방문해주시기 바랍니다.</p>
            <p>관리자에게 마이크로소프트 365 회사 계정을 받은 이후 Kitchen용 계정 생성을 부탁해야 합니다.</p>

            <Link href="https://onioncrew.com">어니언크루 공식 웹사이트</Link>
        </Container>
    )
}

export default NonAuthorizedPage