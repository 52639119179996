import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid, Radio, RadioGroup,
    TextField
} from "@mui/material";
import {DatePicker, TimePicker} from "@mui/x-date-pickers";
import {useEffect, useRef, useState} from "react";
import dayjs from "dayjs";
import {useDispatch} from "react-redux";
import {addNewVacation} from "../../store/VacationsInfo";
import {createAlert} from "../../store/SiteAlerts";


export default function SharedScheduleInput({open, onClose, selectedInfo, userInfo}) {
    const defaultStart = dayjs().add(3,'d').startOf('d').add(9, 'h')
    const defaultEnd = dayjs().add(5, 'd').startOf('d').add(11, 'h')

    const [startDate, setStartDate] = useState(defaultStart)
    const [endDate, setEndDate] = useState(defaultEnd)
    const [inputMethod, setInputMethod] = useState(1)

    const [startTime, setStartTime] = useState(dayjs("2020-01-01 09:00"))
    const [endTime, setEndTime] = useState(dayjs("2020-01-01 11:00"))

    const [comment, setComment] = useState("")

    const dispatch = useDispatch()

    const isInitialized = useRef(false)

    const reset = () => {
        setComment("")
        setInputMethod(1)
        setStartDate(defaultStart)
        setEndDate(defaultEnd)
        isInitialized.current = false
    }

    useEffect(() => {
        if(selectedInfo && !open) {
            if(selectedInfo.start) {
                setStartDate(dayjs(selectedInfo.start).startOf('d'))
            }

            if(selectedInfo.end) {
                setEndDate(dayjs(selectedInfo.end).subtract(1, 'd').startOf('d'))
                setInputMethod(2)
            }

            if(selectedInfo.date) {
                const s = dayjs(selectedInfo.date).startOf('d')
                setInputMethod(1)
                setStartDate(s)
            }
        }
    }, [selectedInfo, open])

    const handleAddNewVacation = () => {
        let tempStartDate = startDate
        let tempEndDate = endDate

        if(comment.trim() === "") {
            dispatch(createAlert("휴가 사유에 대해 기록해 주십시오."))
            return
        }

        if(inputMethod === 0) {
            let start = dayjs(startTime)
            tempStartDate = dayjs(startDate).hour(start.hour()).minute(start.minute())
            let end = dayjs(endTime)
            tempEndDate = dayjs(startDate).hour(end.hour()).minute(end.minute())
        }

        const param = {
            userId: Number(userInfo.id),
            method: Number(inputMethod),
            type: 10,
            startDate: tempStartDate,
            endDate: tempEndDate,
            comment: comment,
        }

        dispatch(addNewVacation({param}))
            .then((res) => {
                if(res.error) {
                    dispatch(createAlert(res.payload.message))
                } else {
                    dispatch(createAlert("일정 작성 완료.", "success"))
                }
            })
            .finally(() => {
                onClose()
                reset()
            })
    }

    return (
        <>
            <Dialog open={open} onClose={() => {
                onClose()
                reset()
            }}>
                <DialogTitle>새 공개 일정 작성</DialogTitle>
                <DialogContent>
                    공개 일정은 휴가와 다르게 첫 페이지에만 표시됩니다.
                </DialogContent>
                <Grid container spacing={2} padding={2}>
                    <Grid item xs={12}>
                        <RadioGroup row value={inputMethod} onChange={(e) => {
                            setInputMethod(Number(e.target.value))
                        }}>
                            <FormControlLabel value={0} control={<Radio />} label="시간 입력" />
                            <FormControlLabel value={1} control={<Radio />} label="일 입력" />
                            <FormControlLabel value={2} control={<Radio />} label="다중 일 입력" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                        {(inputMethod === 0) && (
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <DatePicker
                                        disableMaskedInput={true}
                                        onChange={(v) => setStartDate(v)}
                                        value={startDate}
                                        renderInput={(params) => <TextField {...params} />}
                                        label="일자" />
                                </Grid>
                                <Grid item xs={4}>
                                    <TimePicker
                                        minutesStep={0}
                                        ampmInClock={true}
                                        value={startTime}
                                        mask="__:__"
                                        inputFormat="HH:mm"
                                        onChange={(v) => setStartTime(v)}
                                        renderInput={(params) => <TextField {...params} /> }
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TimePicker
                                        mask="__:__"
                                        inputFormat="HH:mm"
                                        minutesStep={0}
                                        ampmInClock={true}
                                        minTime={startTime}
                                        value={endTime}
                                        onChange={(v) => setEndTime(v)}
                                        renderInput={(params) => <TextField {...params} /> }
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {(inputMethod === 1) && (
                            <DatePicker disableMaskedInput={true} label="시작일" onChange={(v) => setStartDate(v)} value={startDate} renderInput={(params) => <TextField {...params} />} />
                        )}
                        {(inputMethod === 2) && (
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <DatePicker disableMaskedInput={true} label="시작일" onChange={(v) => setStartDate(v)} value={startDate} renderInput={(params) => <TextField {...params} />} />
                                </Grid>
                                <Grid item xs={6}>
                                    <DatePicker
                                        disableMaskedInput={true}
                                        label="종료일"
                                        onChange={(v) => setEndDate(v)}
                                        renderInput={(params) => <TextField {...params} />}
                                        value={endDate}
                                        minDate={startDate}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField required label="내용" multiline={true} fullWidth rows={3} value={comment} onChange={(e) => {
                            setComment(e.target.value)
                        }} />
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button onClick={() => handleAddNewVacation()}>확인</Button>
                    <Button onClick={() => {
                        reset()
                        onClose()
                    }}>취소</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
