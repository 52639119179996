import {vacationStatus} from "../../store/VacationsInfo";
import {Grid, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import {useMemo} from "react";
import dayjs from "dayjs";
import UserName from "../common/UserName";
import DateTimeLabel from "../common/DateTimeLabel";

export default function VacationContent({vacation}) {
    const startDateLabel = useMemo(() => {
        if(!vacation) return ""
        switch(Number(vacation.method)) {
            case 0: return "시작시간"
            case 1: return "휴가일"
            default: return "시작일"
        }
    }, [vacation])

    const endDateLabel = useMemo(() => {
        if(vacation) {
            if (Number(vacation.method) === 0) {
                return "종료시간"
            } else {
                return "종료일"
            }
        }
        return ""
    }, [vacation])

    const period = useMemo(() => {
        if(vacation) {
            switch(Number(vacation.method)) {
                case 0:
                    return dayjs(vacation.endDate).diff(dayjs(vacation.startDate), 'h') + "시간"
                case 1:
                    return "1일"
                default:
                    return dayjs(vacation.endDate).diff(dayjs(vacation.startDate), 'd') + "일"
            }
        }
        return ""
    }, [vacation])

    if(!vacation) {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    현재 존재하지 않는 휴가 기록입니다.
                </Grid>
            </Grid>
        )
    }

    return (
        <TableContainer style={{padding: "1rem"}}>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={2}>
                            <h2>휴가</h2>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            사용자
                        </TableCell>
                        <TableCell>
                            <UserName user={vacation.user} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {startDateLabel}
                        </TableCell>
                        <TableCell width={400}>
                            <DateTimeLabel time={vacation.startDate} showTime={Number(vacation.method) === 0} showMinute={false}/>
                        </TableCell>
                    </TableRow>
                    {vacation && Number(vacation.method) !== 1 && (
                        <TableRow>
                            <TableCell>
                                {endDateLabel}
                            </TableCell>
                            <TableCell>
                                <DateTimeLabel time={vacation.endDate} showTime={Number(vacation.method) === 0} showMinute={false}/>
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>
                            기간
                        </TableCell>
                        <TableCell>
                            {period}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            상태
                        </TableCell>
                        <TableCell>
                            {vacationStatus[vacation.status].label}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}>
                            {vacation.comment}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}