import {IconButton} from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';

function SignInButton() {
    return (
        <IconButton color="inherit" href={`${process.env.REACT_APP_API_SERVER}/auth/login`}>
            <LoginIcon />
        </IconButton>
    )
}

export default SignInButton;