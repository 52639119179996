import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import dayjs from "dayjs";
import {sortComments} from "../components/util";

export const applicantStatusLabel = [
    "지원함", // 0
    "서류 탈락", // 1
    "면접 탈락", // 2
    "서류 합격", // 3
    "채용", // 4
]

export const getApplicant = createAsyncThunk("GET_APPLICANT", async({id}, {rejectWithValue}) => {
    try {
        const response = await axios.get(`/api/v1/applicant/${id}`, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const getApplicants = createAsyncThunk("LIST_APPLICANTS", async (_, {rejectWithValue}) => {
    try {
        const response = await axios.get("/api/v1/applicants", {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const rejectApplicant = createAsyncThunk("REJECT_APPLICANT", async ({id, step}, {rejectWithValue}) => {
    try {
        const response = await axios.post(`/api/v1/applicant/reject`, {
            id, step
        }, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const removeApplicant = createAsyncThunk("REMOVE_APPLICANT", async ({id}, {rejectWithValue}) => {
    try {
        const response = await axios.delete(`/api/v1/applicant/${id}`, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const passApplicant = createAsyncThunk("PASS_APPLICANT", async ({id, step}, {rejectWithValue}) => {
    try {
        const response = await axios.post(`/api/v1/applicant/pass`, {
            id, step
        }, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const updateApplicantMeeting = createAsyncThunk("UPDATE_MEETINGDATE", async({id, date}, {rejectWithValue}) => {
    try {
        const response = await axios.post(`/api/v1/applicant/meeting`, {
            id, date
        }, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const postApplicantComment = createAsyncThunk("POST_COMMENT", async({author, content, parentId, parentType}, {rejectWithValue}) => {
    try {
        const response = await axios.post('/api/v1/comment', {
            author, content, parentId, parentType
        }, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const editApplicantComment = createAsyncThunk("EDIT_COMMENT", async({id, content}, {rejectWithValue}) => {
    try {
        const response = await axios.post(`/api/v1/comment/${id}`, {
            content
        }, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const getApplicantComments = createAsyncThunk("GET_COMMENTS", async({applicantId}, {rejectWithValue}) => {
    try {
        const response = await axios.get(`/api/v1/comments`, {withCredentials: true, params: {
                parentId: applicantId,
                parentType: 'applicants',
            }})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const deleteApplicantComment = createAsyncThunk("DELETE_COMMENT", async({id}, {rejectWithValue}) => {
    try {
        const response = await axios.delete(`/api/v1/comment/${id}`, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const getApplicantHistory = createAsyncThunk("GET_APPLICANT_HISTORY", async({email}, {rejectWithValue}) => {
    try {
        const response = await axios.post('/api/v1/applicant/history', {email}, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const applicantsInfoSlice = createSlice({
    name: "applicantsInfo",
    initialState: {
        applicants: [],
        applicantsLoaded: false,
        applicant: undefined,
        comments: [],
        commentsLoaded: false,
        historyList: [],
        historyLoaded: false
    },
    reducers: {
        clearApplicant: (state) => {
            state.applicant = undefined
            state.comments = []
            state.commentsLoaded = false
            state.historyList = []
            state.historyLoaded = false
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getApplicants.fulfilled, (state, {payload}) => {
                state.applicants = payload
                state.applicantsLoaded = true
            })
            .addCase(getApplicants.rejected, (state) => {
                state.applicants = []
                state.applicantsLoaded = true
            })
            .addCase(getApplicant.fulfilled, (state, {payload}) => {
                state.applicant = payload
                state.commentsLoaded = false
                state.historyLoaded = false
            })
            .addCase(getApplicant.rejected, (state) => {
                state.applicant = undefined
            })
            .addCase(rejectApplicant.fulfilled, (state, {payload}) => {
                let applicants = state.applicants.filter((applicant) => Number(applicant.id) !== Number(payload.id))

                applicants.push(payload)
                applicants.sort(function(a, b) {
                    const date1 = dayjs(a.created)
                    const date2 = dayjs(b.created)

                    return date2.diff(date1)
                })

                state.applicants = applicants
            })
            .addCase(removeApplicant.fulfilled, (state, {payload}) => {
                state.applicants = state.applicants.filter(applicant => Number(applicant.id !== Number(payload.id)))
            })
            .addCase(passApplicant.fulfilled, (state, {payload}) => {
                let applicants = state.applicants.filter((applicant) => Number(applicant.id) !== Number(payload.id))

                applicants.push(payload)
                applicants.sort(function(a, b) {
                    const date1 = dayjs(a.created)
                    const date2 = dayjs(b.created)

                    return date2.diff(date1)
                })

                state.applicants = applicants
            })
            .addCase(updateApplicantMeeting.fulfilled, (state, {payload}) => {
                state.applicant = payload
            })
            .addCase(postApplicantComment.fulfilled, (state, {payload}) => {
                state.comments.push(payload)
                sortComments(state)
            })
            .addCase(getApplicantComments.fulfilled, (state, {payload}) => {
                state.comments = payload
                state.commentsLoaded = true
            })
            .addCase(getApplicantComments.rejected, (state) => {
                state.comments = []
                state.commentsLoaded = true
            })
            .addCase(editApplicantComment.fulfilled, (state, {payload}) => {
                state.comments = state.comments.filter(c => c.id !== payload.id)
                state.comments.push(payload)
                sortComments(state)
            })
            .addCase(deleteApplicantComment.fulfilled, (state, {payload}) => {
                state.comments = state.comments.filter(c => c.id !== payload.id)
                sortComments(state)
            })
            .addCase(getApplicantHistory.fulfilled, (state, {payload}) => {
                state.historyList = payload
                state.historyLoaded = true
            })
            .addCase(getApplicantHistory.rejected, (state) => {
                state.historyList = []
                state.historyLoaded = true
            })
    }
})

export const applicantsInfoSelector = state => state.applicantsInfo;
const applicantsInfoReducer = applicantsInfoSlice.reducer;
export const {clearApplicant} = applicantsInfoSlice.actions
export default applicantsInfoReducer;