import {configureStore} from "@reduxjs/toolkit";
import jobsInfoReducer from "./JobsInfo";
import userInfoReducer from "./UserInfo";
import applicantsInfoReducer from "./ApplicantsInfo";
import membersInfoReducer from "./MembersInfo";
import blacklistReducer from "./BlacklistInfo";
import vacationsInfoReducer from "./VacationsInfo";
import siteAlertReducer from "./SiteAlerts";
import approvalsInfoReducer from "./ApprovalsInfo";
import siteDataInfoReducer from "./SiteDataInfo";
import forumInfoReducer from "./ForumInfo";
import linksInfoReducer from "./LinksInfo";
import vacationsHistoryInfoReducer from "./VacationsHistoryInfo";
import tasksInfoReducer from "./TasksInfo";
import wikiInfoReducer from "./WikiInfo";
import commitsInfoReducer from "./CommitsInfo";
import diagramsInfoReducer from "./DiagramsInfo";

export const store = configureStore({
    reducer: {
        user: userInfoReducer,
        jobsInfo: jobsInfoReducer,
        applicantsInfo: applicantsInfoReducer,
        membersInfo: membersInfoReducer,
        blacklistInfo: blacklistReducer,
        vacationsInfo: vacationsInfoReducer,
        vacationsHistoryInfo: vacationsHistoryInfoReducer,
        siteAlert: siteAlertReducer,
        approvalsInfo: approvalsInfoReducer,
        siteDataInfo: siteDataInfoReducer,
        forumInfo: forumInfoReducer,
        linksInfo: linksInfoReducer,
        tasksInfo: tasksInfoReducer,
        wikiInfo: wikiInfoReducer,
        commitsInfo: commitsInfoReducer,
        diagramsInfo: diagramsInfoReducer,
    }
})
