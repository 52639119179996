import {useMemo, useState} from "react";
import {Box, Button, Grid, Pagination, TablePagination} from "@mui/material";
import useApprovals from "../hooks/useApprovals";
import ApprovalsList, {ApprovalFilters} from "../components/approvals/ApprovalsList";
import {useDispatch} from "react-redux";
import {refreshApprovals} from "../store/ApprovalsInfo";
import useUser from "../hooks/useUser";

export default function ApprovalPage() {
    const userInfo = useUser()
    const {id} = userInfo
    const dispatch = useDispatch()

    const [page, setPage] = useState(0)
    const [count, setCount] = useState(10)
    const {approvals, total} = useApprovals(id, page, count)
    const onPageChange = (_, newPage) => {
        setPage(newPage)
    }
    const onPageClicked = (e, value) => {
        setPage(value - 1)
    }
    const onRowsPerPageChange = e => {
        setPage(0)
        setCount(Number(e.target.value))
    }

    const pages = useMemo(() => {
        let ret = Math.floor(total / count)
        if(total % count > 0) ret++
        return ret
    }, [total, count])

    return (
        <>
            <h1>결재</h1>
            <p>이 페이지에서는 결재와 관련된 내용을 볼 수 있습니다. 이는 추후 기능을 확장하기 위해 제작되었으며 아직은 휴가 관련 기능만 제공합니다. 자동 승인 처리된 항목은 보이지 않습니다.</p>
            <p>요청 내역과 처리 내역은 기안자가 사용자인 경우이며, 결재할 내역과 결재한 내역은 승인자가 사용자인 결재를 보여줍니다.</p>

            <Button size="large" variant="contained" onClick={() => dispatch(refreshApprovals())}>
                새로 고침
            </Button>

            <Box sx={{width: '100%'}} mt={3}>
                <ApprovalsList userInfo={userInfo} approvals={approvals} filters={ApprovalFilters.All} />
                <Grid container flex justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <TablePagination rowsPerPageOptions={[10, 20, 30]} count={total} page={page} rowsPerPage={count} onPageChange={onPageChange} onRowsPerPageChange={onRowsPerPageChange} component="div" />
                    </Grid>
                    <Grid item>
                        <Pagination count={pages} page={page + 1} color="primary" onChange={onPageClicked} />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}