import dayjs from "dayjs";

require('dayjs/locale/ko')


export function isValidVacation(vacation) {
    return (
        vacation.status !== 0 && vacation.status !== 3 && vacation.type === 0
    )
}

export function calcHour(vacation, ignoreStatus = false) {
    const ret = {
        d: 0,
        h: 0,
        total: 0
    }

    if(ignoreStatus === false && !isValidVacation(vacation)) return ret

    switch(vacation.method) {
        case 0:
            ret.h = Math.min(dayjs(vacation.endDate).diff(dayjs(vacation.startDate), 'hour', true), 8)
            ret.total = ret.h
            break
        case 1:
            ret.d = 1
            ret.total = 8
            break
        default:
            ret.d = dayjs(vacation.endDate).add(1, 'day').diff(dayjs(vacation.startDate), 'day')
            ret.total = ret.d * 8
            break
    }

    return ret
}

export function printUsed(vacation, ignoreStatus = false) {
    const ret = calcHour(vacation, ignoreStatus)

    switch(vacation.method) {
        case 0:
            return `${ret.h} 시간`
        case 1:
            return `${ret.d} 일`
        default:
            return `${ret.d} 일`
    }
}