import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";


export const getWikiPage = createAsyncThunk("WIKI", async({pageId, rev="latest"}, {rejectWithValue, requestId, getState}) => {
    try {
        const {pageRequestId} = getState().wikiInfo
        if(pageRequestId && pageRequestId !== requestId) return

        const response = await axios.get(`/api/v1/wiki/${pageId}`, {withCredentials: true, params: {
            rev: rev
        }})

        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const postWikiPage = createAsyncThunk('WIKI/POST', async({formData}, {rejectWithValue}) => {
    try {
        const response = await axios.post(`/api/v1/wiki`, formData, {withCredentials: true, headers: {
            'Content-Type': 'multipart/form-data'
        }})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const deleteWikiAttachedFile = createAsyncThunk("WIKI/ATTACHMENT/DELETE", async({attachmentId, pageId}, {rejectWithValue}) => {
    try {
        const response = await axios.delete(`/api/v1/attachment/${attachmentId}`,
            {
                data: {
                    parentStrId: pageId,
                    parentType: "wiki"
                },
                withCredentials: true
            })
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const getWikiHistory = createAsyncThunk("WIKI/HISTORY", async({pageId}, {rejectWithValue, requestId, getState}) => {
    try {
        const {historyRequestId} = getState().wikiInfo
        if(historyRequestId && historyRequestId !== requestId) return

        const response = await axios.get(`/api/v1/wiki-history/${pageId}`, {withCredentials: true})

        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const deleteWikiPage = createAsyncThunk("WIKI/DELETE", async({pageId, rev, deleteFiles}, {rejectWithValue, requestId, getState}) => {
    try {
        const response = await axios.delete(`/api/v1/wiki/${pageId}`, {data: {rev: rev.toString(), deleteFiles: deleteFiles}, withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const wikiInfoSlice = createSlice({
    name: "wikiInfo",
    initialState: {
        page: null,
        pageId: null,
        rev: null,
        pageLoaded: false,
        pageRequestId: null,

        history: [],
        historyId: null,
        historyLoaded: false,
        historyRequestId: null,
    },
    extraReducers: builder => {
        builder
            .addCase(getWikiPage.pending, (state, {meta}) => {
                if(!state.pageRequestId) {
                    state.pageRequestId = meta.requestId
                    state.pageLoaded = false
                }
            })
            .addCase(getWikiPage.rejected, (state, {meta}) => {
                if(state.pageRequestId && state.pageRequestId === meta.requestId) {
                    state.pageRequestId = null
                    state.pageId = null
                    state.rev = null
                    state.page = null
                    state.pageLoaded = true
                }
            })
            .addCase(getWikiPage.fulfilled, (state, {payload, meta}) => {
                if(state.pageRequestId && state.pageRequestId === meta.requestId) {
                    state.page = payload
                    state.pageRequestId = null
                    state.pageId = meta.arg.pageId
                    state.rev = meta.arg.rev
                    state.pageLoaded = true
                }
            })
            .addCase(postWikiPage.fulfilled, (state, {payload}) => {
                if(state.pageId === payload.pageId) {
                    state.page = payload
                }
            })
            .addCase(deleteWikiAttachedFile.fulfilled, (state, {payload, meta}) => {
                if(state.page && payload.parentStrId && payload.parentStrId === meta.arg.parentStrId) {
                    state.page.files = payload.files
                }
            })
            .addCase(getWikiHistory.pending, (state, {meta}) => {
                if(!state.historyRequestId) {
                    state.historyRequestId = meta.requestId
                    state.historyLoaded = false
                }
            })
            .addCase(getWikiHistory.rejected, (state, {meta}) => {
                if(state.historyRequestId && state.historyRequestId === meta.requestId) {
                    state.historyRequestId = null
                    state.historyId = null
                    state.history = []
                    state.historyLoaded = true
                }
            })
            .addCase(getWikiHistory.fulfilled, (state, {payload, meta}) => {
                if(state.historyRequestId && state.historyRequestId === meta.requestId) {
                    state.history = payload
                    state.historyRequestId = null
                    state.historyId = meta.arg.pageId
                    state.historyLoaded = true
                }
            })
            .addCase(deleteWikiPage.fulfilled, (state, {payload, meta}) => {
                if(state.historyId === meta.arg.pageId) {
                    state.history = state.history.filter(item => item.revision !== meta.arg.rev)
                }
            })
    }
})

export const wikiInfoSelector = state => state.wikiInfo
const wikiInfoReducer = wikiInfoSlice.reducer
export default wikiInfoReducer