import {Typography} from "@mui/material";

function Footer() {
    return (
        <>
            <Typography variant="body2" color="text.secondary" align="center" sx={{mt: 6}}>
                © ONIONCREW.COM
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center" sx={{mt: 1}}>
                이 사이트 내용의 외부 유출을 금합니다.
            </Typography>
        </>
    )
}

export default Footer;