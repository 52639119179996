import DaumPostcode from 'react-daum-postcode';
import {Button} from "@mui/material";
import postCodeInputStyles from "./PostCodeInput.module.css";


export default function PostCodeInput({open, onClose, onComplete, ...props}) {
    const handleComplete = (data) => {
        onComplete(data)
        onClose()
    }

    if(!open) return null

    return (
        <div className={postCodeInputStyles.inputWrapper} onClick={() => onClose()}>
            <div className={postCodeInputStyles.input}>
                <Button variant="contained" onClick={() => onClose()}>닫기</Button>
                <DaumPostcode
                    onComplete={handleComplete}
                    {...props}
                    className={postCodeInputStyles.daumPostCode}
                    height={600}
                />
            </div>
        </div>
    )
}

