import styles from "./Commits.module.css"

export default function CommitFileCount({files, category="added"}) {
    if(!files || files.count === 0) return null

    let className = styles.modifiedFileCount
    let title = "편집됨"

    if(category === "added") {
        className = styles.addedFileCount
        title = "추가됨"
    } else if(category === "removed") {
        className = styles.removedFileCount
        title = "삭제됨"
    }

    return (
        <span className={`${className} ${styles.fileCounter}`} title={title}>
            {files.count}
        </span>
    )
}