import "babel-polyfill"
import React from 'react';
import './index.css';
import App from './App';
import {Provider} from "react-redux";
import {store} from "./store/store";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import isBetween from "dayjs/plugin/isBetween";
import duration from "dayjs/plugin/duration";
import localizedFormat from "dayjs/plugin/localizedFormat";
import {createRoot} from "react-dom/client";
import {AuthProvider} from "./hooks/useUser";

require('dayjs/locale/ko')

dayjs.locale('ko')
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(duration)
dayjs.extend(isBetween)

const fontFamily = `"Noto Sans KR", "--apple-system", "Helvetica", "Arial", sans-serif`

export let theme = createTheme({
    palette: {
        primary: {
            main: '#ff4e55'
        },
        secondary: {
            main: '#808080'
        }
    },
    typography: {
        allVariants: {
            color: '#000'
        },
        fontFamily: fontFamily,
        body1: {
            fontSize: 17,
        },
        body2: { fontSize: 17 },
    },
})

const root = createRoot(document.getElementById('root'))

root.render(
    // <React.StrictMode>
        <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ThemeProvider theme={theme}>
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </ThemeProvider>
            </LocalizationProvider>
        </Provider>
    //</React.StrictMode>
)

