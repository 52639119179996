import UserExtraInfoEditor from "../components/users/UserExtraInfoEditor";
import useUser from "../hooks/useUser";

export default function SetupPage() {
    const userInfo = useUser()
    return (
        <>
            <h1>사용자 설정</h1>
            <p>이름은 본명으로 설정해주세요.</p>
            <UserExtraInfoEditor userId={userInfo.id} userInfo={userInfo} />
        </>
    )
}