import {Button} from "@mui/material";
import {useNavigate} from "react-router";

import "./ShortcutButton.css"


export default function ShortcutButton({to, children, color = "primary"}) {
    const navigate = useNavigate()

    const onClick = () => {
        navigate(to)
    }

    return (
        <Button className="shortcut-button" color={color} onClick={onClick}>{children}</Button>
    )
}