import {
    Button, Dialog,
    DialogActions,
    DialogTitle,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {useMemo, useState} from "react";
import VacationContent from "../vacations/VacationContent";
import DateTimeLabel from "../common/DateTimeLabel";
import {useDispatch} from "react-redux";
import {approvalResultLabel, cancelApproval, changeApprovalResult, deleteApproval} from "../../store/ApprovalsInfo";
import {createAlert} from "../../store/SiteAlerts";
import {vacationStatus} from "../../store/VacationsInfo";
import approvalsStyle from "./Approvals.module.css";
import UserName from "../common/UserName";
import {VacationDuration} from "../vacations/VacationDuration";

function ResultButton({request, acceptance, onTransactionCompleted}) {
    const dispatch = useDispatch()
    const [resultDialog, setResultDialog] = useState(false)

    const buttonLabel = acceptance ? "승인" : "거절"
    const dialogText = acceptance ? "승인을 하시겠습니까?" : "승인을 거부하시겠습니까?"

    return (
        <>
            <Dialog open={resultDialog} onClose={() => setResultDialog(false)}>
                <DialogTitle id="result-confirm">
                    {dialogText}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => {
                        setResultDialog(false)
                        dispatch(changeApprovalResult({id: request.id, acceptance: acceptance}))
                            .then((res) => {
                                if(res.error) {
                                    dispatch(createAlert(res.payload.message))
                                } else {
                                    dispatch(createAlert("결재 완료", "success"))
                                }
                            }).finally(() => {
                                if(onTransactionCompleted) onTransactionCompleted()
                            })
                    }}>네</Button>
                    <Button onClick={() => setResultDialog(false)} autoFocus>아니요</Button>
                </DialogActions>
            </Dialog>
            <Button onClick={() => setResultDialog(true)}>{buttonLabel}</Button>
        </>
    )
}

function DeleteButton({request, onTransactionCompleted}) {
    const dispatch = useDispatch()
    const [deleteDialog, setDeleteDialog] = useState(false)

    return (
        <>
            <Dialog open={deleteDialog} onClose={() => setDeleteDialog(false)}>
                <DialogTitle id="delete-confirm">
                    정말 삭제하시겠습니까? 삭제된 기안은 되돌릴 수 없습니다.
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => {
                        setDeleteDialog(false)

                        dispatch(deleteApproval({id: request.id}))
                            .then((res) => {
                                if(res.error) {
                                    dispatch(createAlert(res.payload.message))
                                } else {
                                    dispatch(createAlert("삭제 완료", "success"))
                                }
                            }).finally(() => {
                                if(onTransactionCompleted) onTransactionCompleted()
                            })
                    }}>네</Button>
                    <Button onClick={() => setDeleteDialog(false)} autoFocus>아니요</Button>
                </DialogActions>
            </Dialog>
            <Button onClick={() => setDeleteDialog(true)}>삭제</Button>
        </>
    )
}

function CancelButton({request, onTransactionCompleted}) {
    const dispatch = useDispatch()
    const [deleteDialog, setDeleteDialog] = useState(false)

    return (
        <>
            <Dialog open={deleteDialog} onClose={() => setDeleteDialog(false)}>
                <DialogTitle id="delete-confirm">
                    기안을 취소하시겠습니까?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => {
                        setDeleteDialog(false)
                        dispatch(cancelApproval({id: request.id}))
                            .then((res) => {
                                if(res.error) {
                                    dispatch(createAlert(res.payload.message))
                                } else {
                                    dispatch(createAlert("취소 완료", "success"))
                                }
                            })
                            .finally(() => {
                                if(onTransactionCompleted) onTransactionCompleted()
                            })
                    }}>네</Button>
                    <Button onClick={() => setDeleteDialog(false)} autoFocus>아니요</Button>
                </DialogActions>
            </Dialog>
            <Button onClick={() => setDeleteDialog(true)}>
                기안 취소
            </Button>
        </>
    )
}

export const ApprovalFilters = {
    Requested : 1,
    Received : 2,
    IncompleteResults : 4,
    CompleteResults: 8,
    AnyResults: 16,
    All : 1|2|16
}

export function getFilteredApprovals(requests, userId, filters) {
    return requests.filter((req) => {
        const resultFilter = (filters & ApprovalFilters.AnyResults) ? true : (
            (filters & ApprovalFilters.IncompleteResults && req.reqResult === 0) ||
            (filters & ApprovalFilters.CompleteResults && req.reqResult !== 0)
        )

        const typeFilter = ((filters & ApprovalFilters.Requested) && req.requested.id === userId) ||
            ((filters & ApprovalFilters.Received) && req.approved.id === userId)

        return resultFilter && typeFilter
    })
}

function ApprovalContentDetail({request}) {
    if(request.reqType === 0 && request.vacation) {
        return (
            <>
                <VacationDuration vacation={request.vacation} ignoreStatus={true} />
            </>
        )
    }

    return null
}

export function ApprovalContent({request}) {
    const typeLabel = (request.reqType === 0) ? "휴가" : "미확인 대상"
    const code = (request.reqType === 0) ? vacationStatus[request.reqCode].label : request.reqCode.toString()
    const [details, setDetails] = useState(false)

    if (request.reqType === 0 && !request.vacation) {
        return (
            <>
                삭제된 휴가의 상태를 변경하는 요청입니다. 삭제하세요.
            </>
        )
    }

    return (
        <>
            <span style={{cursor: 'pointer'}} onClick={() => setDetails(true)}><ApprovalContentDetail request={request} /> {typeLabel}를 <q>{code}</q>으로 변경</span>
            <Dialog open={details} onClose={() => setDetails(false)}>
                {(request.reqType === 0) && (
                    <VacationContent vacation={request.vacation} ignoreStatus={true} showUserName={true} showStatus={false} />
                )}
                <DialogActions>
                    <Button onClick={() => setDetails(false)}>닫기</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default function ApprovalsList({approvals, userInfo, onTransactionCompleted, filters = ApprovalFilters.Requested}) {
    const requestList = useMemo(() => getFilteredApprovals(approvals, userInfo.id, filters), [filters, approvals, userInfo])

    return (
        <div className={approvalsStyle.container}>
            <TableContainer xs={12}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                기안내용
                            </TableCell>
                            <TableCell align="center" width={100}>
                                기안자
                            </TableCell>
                            <TableCell align="center" width={100}>
                                승인자
                            </TableCell>
                            <TableCell align="center" width={100}>
                                결과
                            </TableCell>
                            <TableCell align="center" width={200}>
                                기안시간
                            </TableCell>
                            <TableCell align="center">
                                편집
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {requestList.map(req => {
                            const editable = (Number(req.approved.id) === Number(userInfo.id) && req.reqResult === 0)
                            const cancelable = (Number(userInfo.id) === Number(req.requested.id) || userInfo.roles.users.d) && (Number(req.reqResult) === 0) && !editable
                            const deletable = userInfo.roles.users.d

                            return (
                                <TableRow key={req.id} hover>
                                    <TableCell align="left">
                                        <ApprovalContent request={req}/>
                                    </TableCell>
                                    <TableCell align="center">
                                        <UserName user={req.requested} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <UserName user={req.approved} />
                                    </TableCell>
                                    <TableCell align="center">
                                        {approvalResultLabel[req.reqResult]}
                                    </TableCell>
                                    <TableCell align="center">
                                        <DateTimeLabel style={{margin: '4px 0', display: 'block'}} time={req.created} />
                                    </TableCell>
                                    <TableCell align="center">
                                        {editable && (
                                            <>
                                                <ResultButton acceptance={true} request={req} onTransactionCompleted={onTransactionCompleted}/>
                                                <ResultButton acceptance={false} request={req} onTransactionCompleted={onTransactionCompleted}/>
                                            </>
                                        )}
                                        {cancelable && <CancelButton request={req} onTransactionCompleted={onTransactionCompleted}/> }
                                        {deletable && <DeleteButton request={req} onTransactionCompleted={onTransactionCompleted}/> }
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}