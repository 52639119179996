import useMembers from "../../hooks/useMembers";
import {MenuItem, Select} from "@mui/material";

export default function MemberSelector({userInfo, activeOnly, value, ...props}) {
    let users = useMembers()

    if(activeOnly) users = users.filter(u => u.status === 0)
    if(!users || users.length === 0) value = 0

    const v = (typeof(value) === "object" && value['id']) ? value['id'] : value

    return (
        <>
            <Select value={v} {...props}>
                <MenuItem key={0} value={0}>선택하지 않음</MenuItem>
                {users.map((u) => (
                    <MenuItem key={Number(u.id)} value={Number(u.id)}>{u.displayName} ({u.email})</MenuItem>
                ))}
            </Select>
        </>
    )
}