import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

export const getCommits = createAsyncThunk("FETCH/COMMITS", async (_, {rejectWithValue, requestId, getState}) => {
    try {
        const {commitsRequestId, page, mode, count, keyword} = getState().commitsInfo
        if(commitsRequestId && commitsRequestId !== requestId) return

        const response = await axios.get(`/api/v1/commits`, {withCredentials: true, params: (keyword) ? {mode, page, count, keyword} : {mode, page, count}})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const getCommit = createAsyncThunk("FETCH/COMMIT", async ({id}, {rejectWithValue, requestId, getState}) => {
    try {
        const {commits, selectedCommit, selectedCommitRequestId} = getState().commitsInfo
        if(selectedCommitRequestId && selectedCommitRequestId !== requestId) return

        if(selectedCommit && selectedCommit.id === id) {
            return selectedCommit
        } else {
            const found = commits.find(commit => commit.id === id)
            if(found) {
                return found
            } else {
                const response = await axios.get(`/api/v1/commit/${id}`, {withCredentials: true})
                return response.data
            }
        }
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const commitsInfoSlice = createSlice({
    name: "commitsInfo",
    initialState: {
        commits: [],
        commitsLoaded: false,
        commitsRequestId: null,
        selectedCommit: null,
        selectedCommitLoaded: false,
        selectedCommitRequestId: null,
        mode: undefined, // 기본적으로 시작하는 모드를 정의하지 않는 이유는 어떤 페이지를 먼저 로딩하는지 모르기 때문. 실제적으로 mode가 세팅되었을 때 리퀘스트가 일어나도록 만들었다.
        page: 0,
        count: 20,   // 한 페이지에 표시할 내용
        keyword: undefined,
        total: 0,
    },
    reducers: {
        setCommitsQuery: (state, action) => {
            let {mode, page, count, keyword} = action.payload

            if(count && typeof count === 'string'){
                count = Number(count)
            }

            if(page && typeof page === 'string') {
                page = Number(page)
            }

            let refreshRequired = false

            if(mode && state.mode !== mode) {
                state.mode = mode
                state.page = 0
                refreshRequired = true
            }

            if(page !== null && page !== undefined && state.page !== page) {
                state.page = page
                refreshRequired = true
            }

            if(count !== null && count !== undefined && state.count !== count) {
                state.count = count
                refreshRequired = true
            }

            if(keyword === null) keyword = undefined
            if(keyword !== state.keyword) {
                state.keyword = keyword
                refreshRequired = true
            }

            if(refreshRequired === true) {
                state.commits = []
                state.commitsLoaded = false
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getCommit.pending, (state, {meta}) => {
                if(!state.selectedCommitRequestId) {
                    state.selectedCommitRequestId = meta.requestId
                }
            })
            .addCase(getCommit.fulfilled, (state, {payload, meta}) => {
                if(state.selectedCommitRequestId && state.selectedCommitRequestId === meta.requestId) {
                    state.selectedCommitRequestId = null
                    state.selectedCommit = payload
                    state.selectedCommitLoaded = true
                }
            })
            .addCase(getCommit.rejected, (state, {meta}) => {
                if(state.selectedCommitRequestId && state.selectedCommitRequestId === meta.requestId) {
                    state.selectedCommitRequestId = null
                    state.selectedCommit = null
                    state.selectedCommitLoaded = true
                }
            })
            .addCase(getCommits.pending, (state, {meta}) => {
                if(!state.commitsRequestId) {
                    state.commitsRequestId = meta.requestId
                }
            })
            .addCase(getCommits.fulfilled, (state, {payload, meta}) => {
                if(state.commitsRequestId && state.commitsRequestId === meta.requestId) {
                    state.commitsRequestId = null
                    state.commits = payload.commits
                    state.total = payload.total
                    state.commitsLoaded = true
                }
            })
            .addCase(getCommits.rejected, (state, {meta}) => {
                if(state.commitsRequestId && state.commitsRequestId === meta.requestId) {
                    state.commitsRequestId = null
                    state.commits = []
                    state.total = 0
                    state.commitsLoaded = true
                }
            })
    }
})

export const commitsInfoSelector = state => state.commitsInfo
const commitsInfoReducer = commitsInfoSlice.reducer
export const {setCommitsQuery} = commitsInfoSlice.actions
export default commitsInfoReducer
