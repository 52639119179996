import {
    Button,
    Checkbox,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, FormControlLabel, FormGroup, Grid, Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField, Typography
} from "@mui/material";
import useMembers from "../hooks/useMembers";
import {useState} from "react";
import {addMember, deleteMember, updateMemberRole} from "../store/MembersInfo";
import {useDispatch} from "react-redux";
import UserExtraInfoEditor from "../components/users/UserExtraInfoEditor";
import {useNavigate} from "react-router";
import {createAlert} from "../store/SiteAlerts";
import {statusLabels} from "../utils/labels";
import useUser from "../hooks/useUser";

export default function UsersPage() {
    const userInfo = useUser()
    const users = useMembers()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [selectedUser, setSelectedUser] = useState(null)
    const [newUserOpen, setNewUserOpen] = useState(false)
    const [newUserEmail, setNewUserEmail] = useState("")

    const [rolesOpen, setRolesOpen] = useState(false)
    const [jobsRole, setJobsRole] = useState({v: false, e: false, d: false})
    const [applicantsRole, setApplicantsRole] = useState({v: false, e: false, d: false})
    const [blacklistRole, setBlacklistRole] = useState({v: false, e: false, d: false})
    const [wikiRole, setWikiRole] = useState({v: false, e: false, d: false})
    const [forumsRole, setForumsRole] = useState({v: false, e: false, d: false})
    const [commentsRole, setCommentsRole] = useState({v: false, e: false, d: false})
    const [usersRole, setUsersRole] = useState({v: false, e: false, d: false})

    const [userExtrasId, setUserExtrasId] = useState(-1)
    const [deleteId, setDeleteId] = useState(-1)
    const {roles} = userInfo

    const handleClickRoles = (id) => {
        const user = users.find(u => u.id === id)

        if(user) {
            setSelectedUser(user)

            setJobsRole(user.roles.jobs)
            setApplicantsRole(user.roles.applicants)
            setBlacklistRole(user.roles.blacklist)
            setWikiRole(user.roles.wiki)
            setUsersRole(user.roles.users)
            setCommentsRole(user.roles.comments)
            setForumsRole(user.roles.forums)

            setRolesOpen(true)
        }
    }

    const handleRolesClose = () => {
        setRolesOpen(false)
    }

    const handleSetRoles = () => {
        if(selectedUser === null) return

        const params = {
            id: selectedUser.id,
            roles: {
                jobs: jobsRole,
                applicants: applicantsRole,
                blacklist: blacklistRole,
                forums: forumsRole,
                comments: commentsRole,
                wiki: wikiRole,
                users: usersRole,
            }
        }

        dispatch(updateMemberRole(params)).then((res) => {
            if(res.error) {
                dispatch(createAlert(res.payload.message))
            } else {
                dispatch(createAlert("업데이트 완료", "success"))
            }
        }).finally(() => {
            setRolesOpen(false)
            setSelectedUser(null)
        })
    }


    const handleClickNewUser = () => {
        setNewUserOpen(true)
        setNewUserEmail("")
    }

    const handleCloseNewUser = () => {
        setNewUserEmail("")
        setNewUserOpen(false)
    }

    const handleAddNewUser = () => {
        if(!newUserEmail || newUserEmail.trim() === "") {
            setNewUserOpen(false)
            return
        }

        dispatch(addMember({email: newUserEmail})).then(res => {
            if(res.error) {
                dispatch(createAlert(res.payload.message))
            } else {
                dispatch(createAlert("새로운 사용자가 추가되었습니다.", "info"))
            }
        }).finally(() => {
            setNewUserEmail("")
            setNewUserOpen(false)
        })
    }

    if(!roles.users.v) return null

    return (
        <>
            <h1>승선자 목록</h1>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                이름
                            </TableCell>
                            <TableCell align="center">
                                이메일
                            </TableCell>
                            <TableCell align="center">
                                상태
                            </TableCell>
                            {roles.users.e && (
                                <>
                                    <TableCell align="center">
                                        재직증명서
                                    </TableCell>
                                    <TableCell align="center">
                                        상세정보
                                    </TableCell>
                                    <TableCell align="center">
                                        사용자 관리
                                    </TableCell>
                                </>
                            )}
                            {roles.users.d && (
                                <TableCell align="center">
                                    삭제
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((u) => {
                            return (
                                <TableRow key={`user_${u.id}`} hover>
                                    <TableCell>
                                        {u.displayName}
                                    </TableCell>
                                    <TableCell>
                                        {u.email}
                                    </TableCell>
                                    <TableCell align="center">
                                        {statusLabels[u.status]}
                                    </TableCell>
                                    {roles.users.e && (
                                        <>
                                            <TableCell align="center">
                                                <Link href={`/api/v1/cert/employment?stamp=1&purpose=0&u=${u.id}`} target="_blank" rel="noopener noreferrer">PDF용</Link>
                                                &nbsp;
                                                <Link href={`/api/v1/cert/employment?stamp=0&purpose=0&u=${u.id}`} target="_blank" rel="noopener noreferrer">제출용</Link>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button onClick={() => setUserExtrasId(u.id)} variant="outlined">수정</Button>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button onClick={() => handleClickRoles(u.id)} variant="outlined">권한</Button>
                                            </TableCell>
                                        </>
                                    )}
                                    {roles.users.d && (
                                        <TableCell align="center">
                                            <Button onClick={() => setDeleteId(u.id)} variant="outlined">삭제</Button>
                                        </TableCell>
                                    )}
                                </TableRow>
                            )
                        })}
                        <Dialog open={rolesOpen && (selectedUser !== null)} onClose={handleRolesClose}>
                            <DialogTitle>{selectedUser && (<>{selectedUser.displayName}</>)} 권한설정</DialogTitle>
                            <DialogContent>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <FormGroup>
                                            <FormControlLabel control={<Typography fontWeight="bold" />} label="채용공고" />
                                            <FormControlLabel control={<Checkbox />} label="보기" checked={jobsRole.v} onChange={(e) => setJobsRole({...jobsRole, v: e.target.checked})} />
                                            <FormControlLabel control={<Checkbox />} label="편집" checked={jobsRole.e} onChange={(e) => setJobsRole({...jobsRole, e: e.target.checked})} />
                                            <FormControlLabel control={<Checkbox />} label="삭제" checked={jobsRole.d} onChange={(e) => setJobsRole({...jobsRole, d: e.target.checked})} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormGroup>
                                            <FormControlLabel control={<Typography fontWeight="bold" />} label="지원자" />
                                            <FormControlLabel control={<Checkbox />} label="보기" checked={applicantsRole.v} onChange={(e) => setApplicantsRole({...applicantsRole, v: e.target.checked})} />
                                            <FormControlLabel control={<Checkbox />} label="편집" checked={applicantsRole.e} onChange={(e) => setApplicantsRole({...applicantsRole, e: e.target.checked})} />
                                            <FormControlLabel control={<Checkbox />} label="삭제" checked={applicantsRole.d} onChange={(e) => setApplicantsRole({...applicantsRole, d: e.target.checked})} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormGroup>
                                            <FormControlLabel control={<Typography fontWeight="bold" />} label="위키" />
                                            <FormControlLabel control={<Checkbox />} label="보기" checked={wikiRole.v} onChange={(e) => setWikiRole({...wikiRole, v: e.target.checked})} />
                                            <FormControlLabel control={<Checkbox />} label="편집" checked={wikiRole.e} onChange={(e) => setWikiRole({...wikiRole, e: e.target.checked})} />
                                            <FormControlLabel control={<Checkbox />} label="삭제" checked={wikiRole.d} onChange={(e) => setWikiRole({...wikiRole, d: e.target.checked})} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormGroup>
                                            <FormControlLabel control={<Typography fontWeight="bold" />} label="포럼" />
                                            <FormControlLabel control={<Checkbox />} label="보기" checked={forumsRole.v} onChange={(e) => setForumsRole({...forumsRole, v: e.target.checked})} />
                                            <FormControlLabel control={<Checkbox />} label="편집" checked={forumsRole.e} onChange={(e) => setForumsRole({...forumsRole, e: e.target.checked})} />
                                            <FormControlLabel control={<Checkbox />} label="삭제" checked={forumsRole.d} onChange={(e) => setForumsRole({...forumsRole, d: e.target.checked})} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormGroup>
                                            <FormControlLabel control={<Typography fontWeight="bold" />} label="댓글" />
                                            <FormControlLabel control={<Checkbox />} label="보기" checked={commentsRole.v} onChange={(e) => setCommentsRole({...commentsRole, v: e.target.checked})} />
                                            <FormControlLabel control={<Checkbox />} label="편집" checked={commentsRole.e} onChange={(e) => setCommentsRole({...commentsRole, e: e.target.checked})} />
                                            <FormControlLabel control={<Checkbox />} label="삭제" checked={commentsRole.d} onChange={(e) => setCommentsRole({...commentsRole, d: e.target.checked})} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormGroup>
                                            <FormControlLabel control={<Typography fontWeight="bold" />} label="블랙리스트" />
                                            <FormControlLabel control={<Checkbox />} label="보기" checked={blacklistRole.v} onChange={(e) => setBlacklistRole({...blacklistRole, v: e.target.checked})} />
                                            <FormControlLabel control={<Checkbox />} label="편집" checked={blacklistRole.e} onChange={(e) => setBlacklistRole({...blacklistRole, e: e.target.checked})} />
                                            <FormControlLabel control={<Checkbox />} label="삭제" checked={blacklistRole.d} onChange={(e) => setBlacklistRole({...blacklistRole, d: e.target.checked})} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormGroup>
                                            <FormControlLabel control={<Typography fontWeight="bold" />} label="사용자 관리" />
                                            <FormControlLabel control={<Checkbox />} label="보기" checked={usersRole.v} onChange={(e) => setUsersRole({...usersRole, v: e.target.checked})} />
                                            <FormControlLabel control={<Checkbox />} label="편집" checked={usersRole.e} onChange={(e) => setUsersRole({...usersRole, e: e.target.checked})} />
                                            <FormControlLabel control={<Checkbox />} label="삭제" checked={usersRole.d} onChange={(e) => setUsersRole({...usersRole, d: e.target.checked})} />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleSetRoles}>확인</Button>
                                <Button onClick={handleRolesClose}>취소</Button>
                            </DialogActions>
                        </Dialog>
                    </TableBody>
                </Table>
                <Grid container mt={3}>
                    {roles.users.e && (
                        <>
                            <Grid item>
                            <Button variant="contained" onClick={handleClickNewUser} size="large">
                                새로운 사용자 추가
                            </Button>
                            <Dialog open={newUserOpen} onClose={handleCloseNewUser}>
                                <DialogTitle>Subscribe</DialogTitle>
                                <DialogContent>
                                <DialogContentText>
                                새로운 사용자의 이메일 주소를 입력하세요. 이메일 주소는 Azure Portal에 등록되어 있는 onioncrew.com 계정만 사용 가능합니다.
                                </DialogContentText>
                                <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Email Address"
                                type="email"
                                fullWidth
                                variant="standard"
                                value={newUserEmail}
                                onChange={(e) => setNewUserEmail(e.target.value)}
                                />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleAddNewUser}>추가</Button>
                                    <Button onClick={handleCloseNewUser}>취소</Button>
                                </DialogActions>
                            </Dialog>
                            </Grid>
                            <Grid item>
                                <Button size="large" variant="contained" sx={{marginLeft: "1rem"}} onClick={() => navigate('/positions')}>
                                    직급 관리
                                </Button>
                            </Grid>
                            <Dialog open={userExtrasId !== -1} onClose={() => setUserExtrasId(-1)}>
                                <DialogContent>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <UserExtraInfoEditor
                                                userId={userExtrasId}
                                                userInfo={userInfo}
                                                closeButton={true}
                                                onClose={() => setUserExtrasId(-1)}
                                                onSaved={() => setUserExtrasId(-1)}
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                            </Dialog>
                        </>
                    )}
                    {roles.users.d && (
                        <Dialog open={deleteId !== -1} onClose={() => setDeleteId(-1)}>
                            <DialogTitle id="delete-confirm">
                                정말 삭제하시겠습니까?
                            </DialogTitle>
                            <DialogActions>
                                <Button onClick={() => {
                                    dispatch(deleteMember({id: deleteId})).finally(() => setDeleteId(-1))
                                }}>네</Button>
                                <Button onClick={() => setDeleteId(-1)} autoFocus>아니요</Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </Grid>
            </TableContainer>
        </>
    )
}