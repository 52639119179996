import {
    Button,
    Dialog, DialogActions
} from "@mui/material";
import {vacationStatus, deleteVacation} from "../../store/VacationsInfo";
import {useMemo} from "react";
import {createAlert} from "../../store/SiteAlerts";
import {useDispatch} from "react-redux";
import {addApproval} from "../../store/ApprovalsInfo";
import {reloadSiteData} from "../../store/SiteDataInfo";
import VacationContent from "./VacationContent";

export default function VacationDetails({open, onClose, vacation, user, onCompleted}) {
    const dispatch = useDispatch()

    const editable = useMemo(() => {
        if(!user || !vacation) return false
        return ((user.id === vacation.user.id || vacation.supervisor === user.id || vacation.type > 10) && vacation.id > 0)
    }, [vacation, user])

    const oneClickLabel = useMemo(() => {
        if(vacation) {
            if(vacation.type < 10) {
                const label = vacationStatus[vacation.status].manageLabel
                return (label) ? label : null
            } else {
                return "삭제"
            }
        } else {
            return null
        }
    }, [vacation])

    const handleOneClick = (v) => {
        if(vacation.type < 10) {
            switch (v.status) {
                case 1: // 취소 요청
                    dispatch(addApproval({
                        type: 0, // 휴가
                        document: v.id,
                        code: 3
                    })).then(res => {
                        if (res.error) {
                            dispatch(createAlert(res.payload.message))
                        } else {
                            dispatch(createAlert("완료", "success"))
                        }
                    })
                    if (onCompleted) onCompleted(v)
                    break
                case 2: // 취소 요청 취소
                default:
                    break
            }
        } else {
            dispatch(deleteVacation({id: v.id})).then(() => {
                dispatch(reloadSiteData())
            })
        }
    }

    if(!vacation) return null

    return (
        <Dialog open={open} onClose={onClose}>
            <VacationContent vacation={vacation} />
            <DialogActions>
                {editable && oneClickLabel && (
                    <Button onClick={() => handleOneClick(vacation)}>{oneClickLabel}</Button>
                )}
                <Button onClick={() => onClose()}>닫기</Button>
            </DialogActions>
        </Dialog>
    )
}