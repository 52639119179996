import {taskStatusList} from "../../store/TasksInfo";
import taskStatusStyles from "./TaskStatus.module.css"
import DateTimeLabel from "../common/DateTimeLabel";

export default function TaskStatus({task, showCompletedDate = false}) {
    if(!task) return null

    const {status, done} = task

    return (
        <>
            <span className={taskStatusStyles.label} style={{backgroundColor: taskStatusList[status].bg}}>
                {taskStatusList[status].label}
            </span>
            {(showCompletedDate && status === 1 && done) && (
                <><DateTimeLabel time={done} showTime={false} parentheses={false} /> <DateTimeLabel time={done} showTime={false} parentheses={true} fromNow={true} /></>
            )}
        </>
    )
}

export function TaskStatusText({status}) {
    return (
        <span>
            {taskStatusList[status].label}
        </span>
    )
}