import styles from "./Commits.module.css"
import CommitFileCount from "./CommitFileCount";
import {Link} from "react-router-dom";
import CommitAction from "./CommitAction";

function extractAction(inputString) {
    // 정규식을 사용하여 문자열 시작 부분의 "단어:" 패턴을 찾음
    const regex = /^\s*(\w+)(\(.*?\))?:/;
    const match = inputString.match(regex);

    if (match) {
        const action = match[1];
        const modifiedString = inputString.replace(match[0], '').trim();

        return {action, message: modifiedString};
    } else {
        return {action: null, message: inputString}; // 일치하는 단어가 없는 경우 null 반환
    }
}

function extractFirstLine(message) {
    const lines = message.split('\n');
    return lines[0];
}

export default function CommitLink({commit, showFiles = false}) {
    const {added, removed, modified} = commit
    const {action, message} = extractAction(commit.message)
    const headline = extractFirstLine(message)

    return (
        <>
            <CommitAction action={action} />
            <Link className={styles.message} to={`/commit/${commit.id}`}>{headline}</Link>
            {showFiles && (
                <>
                    <CommitFileCount files={added} category="added" />
                    <CommitFileCount files={modified} category="modified" />
                    <CommitFileCount files={removed} category="removed" />
                </>
            )}
        </>
    )
}