import {useDispatch, useSelector} from "react-redux";
import {getWikiHistory, getWikiPage, wikiInfoSelector} from "../store/WikiInfo";
import {useEffect} from "react";
import {toast} from "react-toastify";
import {useNavigate} from "react-router";

export function useWiki(pageId, rev="latest") {
    const dispatch = useDispatch()
    const wikiInfo = useSelector(wikiInfoSelector)
    const navigate = useNavigate()

    useEffect(() => {
        if(!pageId) return

        if(!wikiInfo.pageLoaded || (wikiInfo.pageId !== pageId) || (wikiInfo.rev !== rev)) {
            dispatch(getWikiPage({pageId, rev})).then(res => {
                if(res.payload && res.payload.message) {
                    toast.error(res.payload.message)
                    navigate("/404")
                }
            }).catch(e => {
                toast.error(e)
            })
        }
    }, [dispatch, navigate, pageId, rev, wikiInfo])

    return {
        page: pageId ? wikiInfo.page: null,
        loading: pageId ? (!wikiInfo.pageLoaded && wikiInfo.pageRequestId !== null) : false,
    }
}

export function useWikiHistory(pageId) {
    const dispatch = useDispatch()
    const wikiInfo = useSelector(wikiInfoSelector)
    const navigate = useNavigate()

    useEffect(() => {
        if(!pageId) return

        if(!wikiInfo.historyLoaded || wikiInfo.historyId !== pageId) {
            dispatch(getWikiHistory({pageId})).then(res => {
                if(res.payload && res.payload.message) {
                    toast.error(res.payload.message)
                    navigate("/404")
                }
            }).catch(e => {
                toast.error(e)
            })
        }
    }, [dispatch, navigate, pageId, wikiInfo])

    return {
        history: pageId ? wikiInfo.history: null,
        loading: pageId ? (!wikiInfo.historyLoaded && wikiInfo.historyRequestId !== null) : false
    }
}