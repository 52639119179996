import styles from './WikiHistory.module.css'
import DateTimeLabel from "../common/DateTimeLabel";
import {Link} from "react-router-dom";
import UserName from "../common/UserName";
import useUser from "../../hooks/useUser";

export function WikiHistory({children}) {
    return (
        <ul className={styles.history}>
            {children}
        </ul>
    )
}

export function WikiRevLabel({revision}) {
    return (
        <span className={styles.revLabel}>
            #{revision}
        </span>
    )
}

function WikiHistoryLabel({item}) {
    const jobLabel = (item.revision === 1) ? "생성됨": "수정됨"

    if(item.status === 0) {
        return (
            <span>{item.subject}({item.pageId}) - {item.author.displayName}에 의해 삭제됨 <WikiRevLabel revision={item.revision} /></span>
        )
    }

    return (
        <>
            <Link to={`/wiki/${item.pageId}?rev=${item.revision}`}>{item.subject} <WikiRevLabel revision={item.revision} /> </Link> {jobLabel} - <UserName user={item.author} />
        </>
    )
}

export function WikiHistoryItem({item, showAdmin = true, onDelete = null}) {
    const userInfo = useUser()
    const deletable = showAdmin && onDelete !== null && userInfo.roles.wiki.d
    const className = (item.status === 0) ? styles.deletedItem : styles.normalItem

    return (
        <li>
            <span className={className}><DateTimeLabel time={item.created}/> <WikiHistoryLabel item={item}/></span>
            {deletable && (
                <>
                    <span className={styles.adminMenu} onClick={() => {
                        if(onDelete) onDelete(item.pageId, item.revision)
                    }}>삭제</span>
                </>
            )}
        </li>
    )
}