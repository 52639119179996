import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {transformVacationData} from "./VacationsInfo";

export const getSiteDataInfo = createAsyncThunk("GET_SITEDATA", async(_, {rejectWithValue}) => {
    try {
        const response = await axios.get('/api/v1/site', {withCredentials: true})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const siteDataInfoSlice = createSlice({
    name: "siteDataInfo",
    initialState: {
        events: [],
        vacations: [],
        approvals: [],
        birthdays: [],
        newCrews: [],
        tasks: [],
        wiki: [],
        commits: [],
        siteDataLoaded: false
    },
    reducers: {
        reloadSiteData: (state) => {
            state.siteDataLoaded = false
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getSiteDataInfo.fulfilled, (state, {payload}) => {
                state.vacations = payload.vacations
                state.events = payload.vacations.map(vacation => transformVacationData(vacation, true))
                state.approvals = payload.approvals
                state.birthdays = payload.birthdays
                state.newCrews = payload.newCrews
                state.tasks = payload.tasks
                state.wiki = payload.wiki
                state.commits = payload.commits
                state.siteDataLoaded = true
            })
            .addCase(getSiteDataInfo.rejected, (state) => {
                state.vacations = []
                state.approvals = []
                state.birthdays = []
                state.newCrews = []
                state.tasks = []
                state.wiki = []
                state.commits = []
                state.siteDataLoaded = true
            })
    }
})

export const siteDataInfoSelector = state => state.siteDataInfo
export const {reloadSiteData} = siteDataInfoSlice.actions
const siteDataInfoReducer = siteDataInfoSlice.reducer
export default siteDataInfoReducer