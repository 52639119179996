import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";

function dataURLtoBlob(dataURL) {
    try {
        const byteString = atob(dataURL.split(',')[1]);
        const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i += 1) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], {type: mimeString});
    } catch(e) {
        return null
    }
}

export default function MarkdownEditor({value, onChange, height = "600px", uploadFiles, setUploadFiles, useFiles = true}) {
    return (
        <>
            <div data-color-mode="light">
            <MDEditor
                highlightEnable={false}
                value={value}
                previewOptions={{
                    rehypePlugins: [[rehypeSanitize]]
                }}
                onChange={onChange}
                height={height}
                onPaste={async (e) => {
                    const clipboardData = e.clipboardData || window.clipboardData || e.originalEvent.clipboardData
                    if(clipboardData.items.length > 0) {
                        for (let i = 0; i < clipboardData.items.length; i++) {
                            if (clipboardData.items[i].kind === 'file') {
                                const file = clipboardData.items[i].getAsFile()
                                const reader = new FileReader()
                                reader.onload = async (e) => {
                                    const blob = dataURLtoBlob(e.target.result)
                                    if(blob) {
                                        const newFile = new File([blob], file.name, {type: file.type})

                                        newFile.preview = URL.createObjectURL(blob)
                                        newFile.path = file.name

                                        setUploadFiles([...uploadFiles, newFile])
                                    }
                                }
                                reader.readAsDataURL(file)
                            }
                        }
                        e.preventDefault()
                    }
                }}
            />
            </div>
        </>
    )
}