import {Link} from "react-router-dom";

function NotFoundPage() {
    return (
        <>
            <h1>404 Not Found</h1>
            <p>저런... 페이지를 찾을 수 없습니다.</p>
            <p><Link to="/">첫 페이지로 가기</Link></p>
        </>
    )
}

export default NotFoundPage;