import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

export const getBlacklist = createAsyncThunk("GET_BLACKLIST", async (_, {rejectWithValue}) => {
    try {
        const response = await axios.get('/api/v1/bl', {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const addBlacklist = createAsyncThunk("ADD_BLACKLIST", async ({email}, {rejectWithValue}) => {
    try {
        const response = await axios.post('/api/v1/bl', {email}, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const deleteBlacklist = createAsyncThunk("DELETE_BLACKLIST", async ({email}, {rejectWithValue}) => {
    try {
        const response = await axios.delete(`/api/v1/bl`, {data: {email}, withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const blacklistInfoSlice = createSlice({
    name: "blacklist",
    initialState: {
        blacklist: [],
        blacklistLoaded: false
    },
    reducers: {

    },
    extraReducers: builder => {
        builder
            .addCase(getBlacklist.fulfilled, (state, {payload}) => {
                state.blacklist = payload
                state.blacklistLoaded = true
            })
            .addCase(getBlacklist.rejected, (state) => {
                state.blacklistLoaded = true
                state.blacklist = []
            })
            .addCase(addBlacklist.fulfilled, (state, {payload}) => {
                state.blacklist.push(payload.email)
                state.blacklist.sort()
            })
            .addCase(deleteBlacklist.fulfilled, (state, {payload}) => {
                state.blacklist = state.blacklist.filter(email => email !== payload.email)
            })
    }
})

export const blacklistSelector = state => state.blacklistInfo
const blacklistReducer = blacklistInfoSlice.reducer
export default blacklistReducer