import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {sortComments} from "../components/util";

export const taskCategory = [
    {
        value: 0,
        label: "프로그래밍",
        short: "P",
        bg: "#FF0032"
    },
    {
        value: 1,
        label: "그래픽",
        short: "G",
        bg: "#3C79F5"
    },
    {
        value: 2,
        label: "기획",
        short: "D",
        bg: "#FFB100",
    },
    {
        value: 3,
        label: "사운드",
        short: "S",
        bg: "#58287F",
    },
    {
        value: 4,
        label: "버그리포트",
        short: "B",
        bg: "#667a57",
    }
]

export const taskStatusList = [
    {
        value: 0,
        label: "진행",
        bg: "#3e7c3e"
    },
    {
        value: 1,
        label: "완료",
        bg: "#233d5e"
    },
    {
        value: 2,
        label: "보류",
        bg: "#404040"
    },
    {
        value: 3,
        label: "취소",
        bg: "#8f3d3d"
    },
    {
        value: 4,
        label: "계획",
        bg: "#eab13c"
    }
]

export const getTaskComments = createAsyncThunk("TASK/COMMENTS", async({taskId}, {rejectWithValue, requestId, getState}) => {
    try {
        const {commentsRequestId} = getState().tasksInfo
        if(commentsRequestId && commentsRequestId !== requestId) return

        const response = await axios.get(`/api/v1/comments`, {withCredentials: true, params: {
            parentId: taskId,
            parentType: 'tasks'
        }})

        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const postTaskComment = createAsyncThunk("TASK/COMMENT/POST", async({author, content, parentId, parentType}, {rejectWithValue}) => {
    try {
        const response = await axios.post('/api/v1/comment', {
            author, content, parentId, parentType
        }, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const deleteTask = createAsyncThunk("TASK/DELETE", async ({taskId}, {rejectWithValue})=> {
    try {
        const response = await axios.delete(`/api/v1/task/${taskId}`, {withCredentials: true})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const deleteTaskAttachedFile = createAsyncThunk("TASK_ATTACHMENTS/DELETE", async({attachmentId, taskId}, {rejectWithValue}) => {
    try {
        const response = await axios.delete(`/api/v1/task/attachment/${attachmentId}`, {data: {taskId}, withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const getTasks = createAsyncThunk("TASKS", async(_, {rejectWithValue, requestId, getState}) => {
    try {
        const {tasksRequestId, page, mode, count, keyword} = getState().tasksInfo
        if(tasksRequestId && (tasksRequestId !== requestId)) return

        const response = await axios.get(`/api/v1/tasks`, {withCredentials: true, params: (keyword) ? {mode, page, count, keyword} : {mode, page, count}})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const getTask = createAsyncThunk("TASK", async({taskId}, {rejectWithValue, dispatch}) => {
    try {
        const response = await axios.get(`/api/v1/task/${taskId}`, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const postTask = createAsyncThunk('TASK/POST', async({formData}, {rejectWithValue}) => {
    try {
        const response = await axios.post(`/api/v1/task`, formData, {withCredentials: true, headers: {
            'Content-Type': 'multipart/form-data'
        }})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})


export const editTaskComment = createAsyncThunk("TASK/COMMENT/EDIT", async({id, content}, {rejectWithValue}) => {
    try {
        const response = await axios.post(`/api/v1/comment/${id}`, {
            content
        }, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const deleteTaskComment = createAsyncThunk("TASK/COMMENT/DELETE", async({id}, {rejectWithValue}) => {
    try {
        const response = await axios.delete(`/api/v1/comment/${id}`, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const changeTaskStatus = createAsyncThunk("TASK/STATUS", async({taskId, status}, {rejectWithValue}) => {
    try {
        const response = await axios.post(`/api/v1/task/status/${taskId}`, {status}, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const tasksInfoSlice = createSlice({
    name: "taskInfo",
    initialState: {
        tasks: [],
        tasksLoaded: false,
        selectedTask: null,
        selectedTaskHistory: [],
        selectedTaskLoaded: false,
        mode: undefined, // 기본적으로 시작하는 모드를 정의하지 않는 이유는 어떤 페이지를 먼저 로딩하는지 모르기 때문. 실제적으로 mode가 세팅되었을 때 리퀘스트가 일어나도록 만들었다.
        page: 0,
        count: 20,   // 한 페이지에 표시할 내용
        keyword: undefined,
        total: 0,
        comments: [],
        commentsLoaded: false,
        tasksRequestId: null,
        commentsRequestId: null,
    },
    reducers: {
        setTasksQuery: (state, action) => {
            let {mode, page, count, keyword} = action.payload

            if(count && typeof count === 'string'){
                count = Number(count)
            }

            if(page && typeof page === 'string') {
                page = Number(page)
            }

            let refreshRequired = false

            if(mode && state.mode !== mode) {
                state.mode = mode
                state.page = 0
                refreshRequired = true
            }

            if(page !== null && page !== undefined && state.page !== page) {
                state.page = page
                refreshRequired = true
            }

            if(count !== null && count !== undefined && state.count !== count) {
                state.count = count
                refreshRequired = true
            }

            if(keyword === null) keyword = undefined
            if(keyword !== state.keyword) {
                state.keyword = keyword
                refreshRequired = true
            }

            if(refreshRequired === true) {
                state.tasks = []
                state.total = 0
                state.tasksLoaded = false
            }
        },
        clearTasks: (state) => {
            state.tasks = []
            state.tasksLoaded = false
        },
        clearSelectedTask: (state) => {
            state.selectedTask = null
            state.selectedTaskHistory = []
            state.selectedTaskLoaded = false
            state.comments = []
            state.commentsLoaded = false
        },
        clearTaskComments: (state) => {
            state.comments = []
            state.commentsLoaded = false
        }
    },
    extraReducers: builder => {
        builder
            .addCase(postTask.fulfilled, (state, {payload}) => {
                state.tasksLoaded = false
                if(state.selectedTask && state.selectedTask.id === payload["task"].id) {
                    state.selectedTask = payload["task"]
                    state.selectedTaskHistory = payload["history"]
                    state.selectedTaskLoaded = true
                    state.tasksLoaded = false
                }
            })
            .addCase(changeTaskStatus.fulfilled, (state, {payload}) => {
                if(state.selectedTask && state.selectedTask.id === payload["task"].id) {
                    state.selectedTask = payload["task"]
                    state.selectedTaskHistory = payload["history"]
                    state.selectedTaskLoaded = true
                } else {
                    state.selectedTask = null
                    state.selectedTaskHistory = []
                    state.tasksLoaded = false
                }
            })
            .addCase(getTask.fulfilled, (state, {payload}) => {
                state.selectedTask = payload["task"]
                state.selectedTaskHistory = payload["history"]
                state.selectedTaskLoaded = true
            })
            .addCase(getTask.rejected, (state) => {
                state.selectedTask = null
                state.selectedTaskHistory = []
                state.selectedTaskLoaded = true
            })
            .addCase(getTasks.fulfilled, (state, {payload, meta}) => {
                const {requestId} = meta
                if(state.tasksRequestId && state.tasksRequestId === requestId) {
                    state.tasks = payload.tasks
                    state.total = payload.total
                    state.tasksRequestId = null
                    state.tasksLoaded = true
                }
            })
            .addCase(getTasks.pending, (state, {meta}) => {
                if(!state.tasksRequestId) {
                    state.tasksRequestId = meta.requestId
                }
            })
            .addCase(getTasks.rejected, (state, {meta}) => {
                if(state.tasksRequestId && state.tasksRequestId === meta.requestId) {
                    state.tasksRequestId = null

                    state.tasks = []
                    state.total = 0
                    state.tasksLoaded = true
                }
            })
            .addCase(getTaskComments.fulfilled, (state, {payload, meta}) => {
                if(state.commentsRequestId && state.commentsRequestId === meta.requestId) {
                    state.comments = payload
                    state.commentsRequestId = undefined
                    state.commentsLoaded = true
                }
            })
            .addCase(getTaskComments.rejected, (state, {meta}) => {
                if(state.commentsRequestId && state.commentsRequestId === meta.requestId) {
                    state.commentsRequestId = null
                    state.comments = []
                    state.commentsLoaded = true
                }
            })
            .addCase(getTaskComments.pending, (state, {meta}) => {
                if(!state.commentsRequestId) {
                    state.commentsRequestId = meta.requestId
                }
            })
            .addCase(deleteTaskAttachedFile.fulfilled, (state, {payload}) => {
                if(payload.taskId && state.selectedTask.id === payload.taskId) {
                    state.selectedTask.files = payload.files
                }
            })
            .addCase(deleteTask.fulfilled, (state, {payload}) => {
                if(state.selectedTask && payload.id === state.selectedTask.id) {
                    state.selectedTask = null
                    state.selectedTaskHistory = []
                    state.selectedTaskLoaded = false
                }
                state.tasksLoaded = false
            })
            .addCase(postTaskComment.fulfilled, (state, {payload}) => {
                state.comments.push(payload)
            })
            .addCase(editTaskComment.fulfilled, (state, {payload}) => {
                state.comments = state.comments.filter(c => c.id !== payload.id)
                state.comments.push(payload)
                sortComments(state)
            })
            .addCase(deleteTaskComment.fulfilled, (state, {payload}) => {
                state.comments = state.comments.filter(c => c.id !== payload.id)
                sortComments(state)
            })
    }
})

export const tasksInfoSelector = state => state.tasksInfo
const tasksInfoReducer = tasksInfoSlice.reducer
export const {clearTasks, clearSelectedTask, setTasksQuery, clearTaskComments} = tasksInfoSlice.actions
export default tasksInfoReducer