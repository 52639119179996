import {
    FormControl,
    InputLabel, Link,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import {useMemo, useState} from "react";
import {Select} from "@mui/material";

const supportCerts = [
    {
        "label": "재직증명서",
        "id": "employment"
    }
]

const purposes = [
    {
        label: "공란으로 비움",
        value: 0,
    },
    {
        label: "은행 제출용",
        value: 1,
    },
    {
        label: "관공서 제출용",
        value: 2,
    },
    {
        label: "직접 작성",
        value: 3
    }
]

export default function CertsPage() {
    const [purpose, setPurpose] = useState(purposes[0].value)
    const [purposeText, setPurposeText] = useState()

    const purposeEncoded = useMemo(() => {
        if(purpose === 3) {
            return encodeURI(purposeText)
        }
        return purpose
    }, [purpose, purposeText])

    return (
        <>
            <h1>증명서 발급</h1>
            <p>지원되지 않거나 추가를 원하는 증명서는 관리자에게 문의하시기 바랍니다. PDF 제출용에는 회사의 직인이 자동으로 포함됩니다. 서류 제출용은 실제 서류가 필요한 경우 발급하며 직인은 관리자에게 문의하시기 바랍니다.</p>
            <p>PDF 변환을 지원하진 않으니 운영체제에서 프린터를 "PDF로 출력" 또는 "Microsoft Print to PDF"를 이용하시기 바랍니다.</p>
            <FormControl fullWidth>
                <InputLabel>용도</InputLabel>
                <Select
                    value={purpose}
                    label="용도"
                    onChange={e => {
                        setPurpose(e.target.value)
                    }}
                >
                    {purposes.map((purpose, index) => {
                        return (<MenuItem key={purpose.value} value={purpose.value}>{purpose.label}</MenuItem>)
                    })
                    }
                </Select>
                {(purpose === 3) && (
                    <TextField variant="outlined" value={purposeText} onChange={e => setPurposeText(e.target.value)} placeholder="용도를 직접 작성하세요." style={
                        {marginTop: "8px"}
                    } />
                )}
            </FormControl>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">
                            종류
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {supportCerts && supportCerts.map(cert => {
                        return (
                            <TableRow key={cert.id}>
                                <TableCell align="left">
                                    <Link href={`/api/v1/cert/${cert.id}?stamp=1&purpose=${purposeEncoded}`} target="_blank" rel="noreferrer noopener">{cert.label}</Link>(PDF)
                                    &nbsp;
                                    <Link href={`/api/v1/cert/${cert.id}?stamp=0&purpose=${purposeEncoded}`} target="_blank" rel="noreferrer noopener">{cert.label}</Link>(실물 제출용)
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </>
    )
}