import {createSlice} from "@reduxjs/toolkit";

export const siteAlertSlice = createSlice({
    name: 'siteAlert',
    initialState: {
        alerts: [],
    },
    reducers: {
        createAlert: {
            reducer: (state, {payload}) => {
                state.alerts.push({
                    message: payload.message,
                    type: payload.type
                })
            },
            prepare: (message, type = "error") => {
                return {
                    payload: {
                        type: type,
                        message: message
                    }
                }
            }
        },
        deleteAlert: (state) => {
            state.alerts = []
        }
    }
})

export const {createAlert, deleteAlert} = siteAlertSlice.actions
export const siteAlertSelector = state => state.siteAlert
const siteAlertReducer = siteAlertSlice.reducer
export default siteAlertReducer