import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid'
import koLocale from '@fullcalendar/core/locales/ko'
import dayjs from "dayjs";
import useSiteData from "../hooks/useSiteData";
import ApprovalsList, {ApprovalFilters} from "../components/approvals/ApprovalsList";
import {useDispatch} from "react-redux";
import {reloadSiteData} from "../store/SiteDataInfo";
import {useMemo, useState} from "react";
import VacationDetails from "../components/vacations/VacationDetails";
import {Button, Grid} from "@mui/material";
import SharedScheduleInput from "../components/main/SharedScheduleInput";
import interactionPlugin from "@fullcalendar/interaction";
import Tasks from "../components/tasks/Tasks";
import TaskItem from "../components/tasks/TaskItem";
import {useTasks} from "../hooks/useTasks";
import ShortcutButton from "../components/common/ShortcutButton";
import Celebrate from "../components/main/Celebrate";
import useUser from "../hooks/useUser";
import {WikiHistory, WikiHistoryItem} from "../components/wiki/WikiHistory";
import Commits from "../components/commits/Commits";
import Commit from "../components/commits/Commit";

function MainPage() {
    const userInfo = useUser()
    const [currentEvent, setCurrentEvent] = useState(null)
    const [selectedInfo, setSelectedInfo] = useState(null)
    const [newScheduleOpen, setNewScheduleOpen] = useState(false)

    const validStart = dayjs().startOf('month').subtract(1, 'month').format("YYYY-MM-DD")
    const validEnd = dayjs().startOf('month').add(2, 'month').format("YYYY-MM-DD")

    const siteData = useSiteData()
    const dispatch = useDispatch()

    const {tasks} = useTasks("current", 0, 20, undefined)
    const currentVacation = useMemo(() => {
        if(currentEvent) {
            return siteData.vacations.find(v => Number(v.id) === Number(currentEvent.id))
        } else {
            return null
        }
    }, [siteData, currentEvent])

    return (
            <Grid container spacing={2} style={{marginTop: "2rem"}}>
                <Celebrate siteDataInfo={siteData} />
                <Grid item xs={12}>
                    <h2>일정 및 휴가<ShortcutButton to="/vacation">바로가기</ShortcutButton></h2>
                    <FullCalendar plugins={[dayGridPlugin, interactionPlugin]}
                                  initialView="dayGridMonth"
                                  locale={koLocale}
                                  selectable={true}
                                  select={(info) => {
                                      setSelectedInfo(info)
                                  }}
                                  unselect={() => {
                                      setSelectedInfo(null)
                                  }}
                                  unselectAuto={false}
                                  unselectCancel={".add-vacation-button"}
                                  eventColor="#ff4e55"
                                  validRange={{
                                      start: validStart,
                                      end: validEnd
                                  }}
                                  events={siteData.events}
                                  height={700}
                                  headerToolbar={{
                                      start: '',
                                      center: '',
                                      end: 'today prev,next'
                                  }}
                                  eventClick={(info) => {
                                      if(info.event && info.event._def && info.event._def.publicId > 0) {
                                          setCurrentEvent(info.event)
                                      }
                                  }}
                                  editable={true}
                    />
                    <VacationDetails
                        open={currentEvent != null}
                        onClose={() => setCurrentEvent(null)}
                        vacation={currentVacation} height={700}
                        onCompleted={() => {
                            dispatch(reloadSiteData())
                        }}
                        user={userInfo}
                        showButtons={false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" size="large" className="add-vacation-button" onClick={() => {
                        setNewScheduleOpen(true)
                    }}>공개 일정 작성</Button>
                    <SharedScheduleInput open={newScheduleOpen} userInfo={userInfo} onClose={() => {
                        setNewScheduleOpen(false)
                    }} selectedInfo={selectedInfo} />
                    <Button variant="contained" style={{marginLeft: '1rem'}} size="large" className="add-vacation-button" color="secondary" onClick={() => {
                        dispatch(reloadSiteData())
                    }}>새로 고침</Button>
                </Grid>
                <Grid item xs={12}>
                    <h2>작업목록<ShortcutButton to="/tasks">바로가기</ShortcutButton></h2>
                    <p>가장 최근 업데이트 된 진행 중인 작업 목록을 크루마다 1개만 보여줍니다.</p>
                    <Tasks showId={false}>
                        {tasks && tasks.map((task, index) =>
                            <TaskItem key={index} task={task} fromNow={true} showId={false}/>
                        )}
                    </Tasks>
                </Grid>
                {siteData.commits.length > 0 && (
                    <Grid item xs={12}>
                        <h2>커밋<ShortcutButton to="/commits">바로가기</ShortcutButton></h2>
                        <p>최근 72시간 이내의 커밋을 최대 20개까지 보여줍니다.</p>
                        <Commits>
                            {siteData.commits.map((commit, index) =>
                                <Commit key={index} commit={commit} fromNow={true} />
                            )}
                        </Commits>
                    </Grid>
                )}
                {siteData.wiki.length > 0 && (
                    <Grid item xs={12}>
                        <h2>갱신된 페이지<ShortcutButton to="/wiki">바로가기</ShortcutButton></h2>
                        <p>최근에 업데이트 된 페이지는 다음과 같습니다.</p>
                        <WikiHistory>
                            {siteData.wiki.map((history, index) =>
                                <WikiHistoryItem key={index} item={history} showAdmin={false} />
                            )}
                        </WikiHistory>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <h2>미결재 항목</h2>
                    <ApprovalsList userInfo={userInfo} approvals={siteData.approvals} filters={ApprovalFilters.All} onTransactionCompleted={() => {
                        dispatch(reloadSiteData())
                    }}/>
                </Grid>
            </Grid>
    )
}

export default MainPage;