import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios";
import dayjs from "dayjs";

export const categoryLabel = {
    "programming": "프로그래밍",
    "game design": "기획",
    "art": "그래픽"
}

export const jobStatusLabel = [
    "숨김",
    "게시됨",
]

export const getCategoryLabel = (name) => {
    return (name in categoryLabel) ? categoryLabel[name] : name
}

export const getCategories = createAsyncThunk("REQUEST_CATEGORIES", async (_, {rejectWithValue}) => {
    try {
        const response = await axios.get("/api/v1/category", {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const postJobPost = createAsyncThunk("POST_JOB", async ({id, category, subject, content, status}, {rejectWithValue}) => {
    try {
        const response = await axios.post("/api/v1/job", {
            id: id,
            category: category,
            subject: subject,
            content: content,
            status: status
        }, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const getJobPosts = createAsyncThunk("LIST_JOBS", async (_, {rejectWithValue}) => {
    try {
        const response = await axios.get("/api/v1/jobs", {withCredentials: true})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const getJobPost = createAsyncThunk("GET_JOB", async ({id}, {rejectWithValue}) => {
    try {
        const response = await axios.get(`/api/v1/job/${id}`, {withCredentials: true})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const deleteJobPost = createAsyncThunk("DELETE_JOB", async ({id}, {rejectWithValue}) => {
    try {
        const response = await axios.delete(`/api/v1/job/${id}`, {withCredentials: true})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const jobsInfoSlice = createSlice({
    name: "jobsInfo",
    initialState: {
        job: undefined,
        jobs: [],
        category: [],
        categoryLoaded: false,
        jobsLoaded: false,
    },
    reducers: {
        resetJobPost: (state) => {
            state.job = undefined
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getCategories.fulfilled, (state, {payload}) => {
                state.category = payload
                state.categoryLoaded = true
            })
            .addCase(getCategories.rejected, (state, action) => {
                state.category = []
                state.categoryLoaded = false
            })
            .addCase(postJobPost.fulfilled, (state, {payload}) => {
                let jobs = state.jobs.filter((job) => Number(job.id) !== Number(payload.id))

                jobs.push(payload)
                jobs.sort(function(a, b) {
                    const date1 = dayjs(a.created)
                    const date2 = dayjs(b.created)

                    return date2.diff(date1)
                })

                state.jobs = jobs
            })
            .addCase(getJobPosts.fulfilled, (state, {payload}) => {
                state.jobs = payload
                state.jobsLoaded = true
            })
            .addCase(getJobPosts.rejected, (state, action) => {
                state.isError = true
                state.jobs = []
                state.jobsLoaded = false
            })
            .addCase(getJobPost.fulfilled, (state, {payload}) => {
                state.job = payload
            })
            .addCase(deleteJobPost.fulfilled, (state, {payload}) => {
                state.jobs = state.jobs.filter(job => Number(job.id) !== Number(payload.id))
            })
    }
})

export const jobsInfoSelector = state => state.jobsInfo
const jobsInfoReducer = jobsInfoSlice.reducer

export const {resetJobPost} = jobsInfoSlice.actions
export default jobsInfoReducer