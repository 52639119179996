import {toast} from "react-toastify";
import styles from './TaskId.module.css'

export default function TaskId({children}) {
    const text = `#${children}`

    const onClick = () => {
        navigator.clipboard.writeText(text).then(() => { toast.info("복사되었습니다.") })
    }

    return (
        <span className={styles.taskId} onClick={onClick}>
            {text}
        </span>
    )
}