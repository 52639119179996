import {TableCell, TableRow} from "@mui/material";
import tasksStyle from "./Tasks.module.css"
import {Link, useNavigate} from "react-router-dom";
import UserName from "../common/UserName";
import TaskStatus from "./TaskStatus";
import {useLocation} from "react-router";
import DateTimeLabel from "../common/DateTimeLabel";
import React from "react";
import TaskCategory from "./TaskCategory";
import TaskId from "./TaskId";

const taskStyle = {
    0: tasksStyle.taskInProgress,
    1: tasksStyle.taskCompleted,
    2: tasksStyle.taskDelayed,
    3: tasksStyle.taskCanceled,
}

function TaskUser({user, author}) {
    const navigate = useNavigate()
    const onUserNameClick = (id) => {
        navigate(`/tasks?mode=user&keyword=${id}`)
    }

    if(user && author && user.id === author.id) {
        return (
            <TableCell align="center">
                <UserName user={user} onClick={() => onUserNameClick(user.id)} />
            </TableCell>
        )
    }

    return (
        <TableCell align="center">
            <UserName user={user} onClick={() => onUserNameClick(user.id)} defaultLabel="<미지정>" /> / <UserName user={author} onClick={() => onUserNameClick(author.id)} defaultLabel="<미지정>" />
        </TableCell>
    )
}

export default function TaskItem({task, showId = true, fromNow = false}) {
    const location = useLocation()

    return (
        <TableRow className={tasksStyle.item}>
            {showId && (
                <TableCell align="center">
                    <TaskId>{task.id}</TaskId>
                </TableCell>
            )}
            <TableCell align="left" className={tasksStyle.titleCell}>
                <TaskCategory category={task.category} short={true} status={task.status} />
                <Link className={taskStyle[task.status]} to={`/task/${task.id}${location.search}`}>{task.subject}</Link>
            </TableCell>
            <TableCell align="center">
                <TaskStatus task={task} />
            </TableCell>
            <TaskUser user={task.user} author={task.author} />
            <TableCell align="center">
                <DateTimeLabel time={task.started} showTime={false} parentheses={false} fromNow={fromNow} showYear={false} />
            </TableCell>
            <TableCell align="center">
                <DateTimeLabel time={task.estimated} showTime={false} parentheses={false} fromNow={fromNow} showYear={false} />
            </TableCell>
        </TableRow>
    )
}