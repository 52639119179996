import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

export const getForumInfo = createAsyncThunk("GET_FORUM_INFO", async({forumId}, {rejectWithValue, getState, dispatch}) => {
    try {
        const response = await axios.get(`/api/v1/forum/${forumId}/info`)
        dispatch(setForumId({payload: {forumId: forumId}}))
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const forumInfoSlice = createSlice({
    name: "forumInfo",
    initialState: {
        forumId: null,
        posts: [],
        total: 0,
        categories: [],
        title: "",
        useCategories: false,
        infoLoaded: false,
        postsLoaded: false,
        forumInfoRequestId: null,
    },
    reducers: {
        clearInfo: (state) => {
            state.posts = []
            state.categories = []
            state.title = ""
            state.useCategories = false
            state.infoLoaded = false
            state.postsLoaded = false
            state.forumId = null
            state.forumInfoRequestId = null
        },
        setForumId: (state, {payload}) => {
            state.forumId = payload.forumId
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getForumInfo.fulfilled, (state, {payload, meta}) => {
                if(state.forumInfoRequestId && state.forumInfoRequestId === meta.requestId) {
                    state.categories = payload.categories
                    state.title = payload.setting.title
                    state.useCategories = payload.setting.useCategories
                    state.infoLoaded = true
                }
            })
            .addCase(getForumInfo.rejected, (state, {meta}) => {
                if(state.forumInfoRequestId && state.forumInfoRequestId === meta.requestId) {
                    state.categories = []
                    state.title = ""
                    state.useCategories = false
                    state.infoLoaded = true
                }
            })
            .addCase(getForumInfo.pending, (state, {meta}) => {
                if(!state.forumInfoRequestId) {
                    state.forumInfoRequestId = meta.requestId
                }
            })
    }
})

export const forumInfoSelector = state => state.forumInfo
export const {clearInfo, setForumId} = forumInfoSlice.actions
const forumInfoReducer = forumInfoSlice.reducer
export default forumInfoReducer
