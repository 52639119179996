import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import dayjs from "dayjs";

export const approvalResultLabel = [
    "검토중",
    "승인",
    "거부"
]

// getApprovals - 결재 내역을 가져옵니다.
// id: 사용자 아이디
export const getApprovals = createAsyncThunk("GET_APPROVAL_REQS", async({id}, {rejectWithValue, getState}) => {
    try {
        const {page, count} = getState().approvalsInfo
        const response = await axios.get(`/api/v1/approvals/${id}`, {withCredentials: true, params: {page, count}})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const deleteApproval = createAsyncThunk("DELETE_APPROVAL_REQ", async({id}, {rejectWithValue}) => {
    try {
        const response = await axios.delete(`/api/v1/approval/${id}`, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const cancelApproval = createAsyncThunk("CANCEL_APPROVAL_REQ", async({id}, {rejectWithValue}) => {
    try {
        const response = await axios.delete(`/api/v1/approval/cancel/${id}`, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const changeApprovalResult = createAsyncThunk("CHANGE_APPROVAL_RESULT", async({id, acceptance}, {rejectWithValue}) => {
    try {
        const response = await axios.post('/api/v1/approval/result', { id, acceptance }, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const addApproval = createAsyncThunk("ADD_APPROVAL", async({type, document, code}, {rejectWithValue}) => {
    try {
        const response = await axios.post('/api/v1/approval', {type, document, code}, {withCredentials: true})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const approvalsInfoSlice = createSlice({
    name: "approvalsInfo",
    initialState: {
        page: 0,
        count: 10, // 한 페이지에서 표시할 내용
        total: 0,
        approvals: [],
        approvalsLoaded: false,
    },
    reducers: {
        setApprovalsQuery: (state, {payload}) => {
            let dirt = false

            if(state.count !== Number(payload.count)) {
                state.count = Number(payload.count)
                dirt = true
            }
            if(state.page !== Number(payload.page)) {
                state.page = Number(payload.page)
                dirt = true
            }

            if(dirt === true) state.approvalsLoaded = false
        },
        refreshApprovals: (state) => {
            state.approvalsLoaded = false
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getApprovals.fulfilled, (state, {payload}) => {
                state.approvals = payload.approvals
                state.total = payload.total
                state.approvalsLoaded = true
            })
            .addCase(getApprovals.rejected, (state) => {
                state.approvals = []
                state.total = 0
                state.approvalsLoaded = true
            })
            .addCase(cancelApproval.fulfilled, (state, {payload}) => {
                state.approvals = state.approvals.filter(req => req.id !== payload.id)
            })
            .addCase(deleteApproval.fulfilled, (state, {payload}) => {
                state.approvals = state.approvals.filter(req => req.id !== payload.id)
            })
            .addCase(changeApprovalResult.fulfilled, (state, {payload}) => {
                state.approvals = state.approvals.filter(req => req.id !== payload.id)
                state.approvals.push(payload)
                sortApprovals(state)
            })
            .addCase(addApproval.fulfilled, (state, {payload}) => {
                state.approvalsLoaded = false
            })
    }
})

function sortApprovals(state) {
    state.approvals.sort(function(a, b) {
        const date1 = dayjs(a.updated)
        const date2 = dayjs(b.updated)

        return date2.diff(date1)
    })
}

export const approvalsInfoSelector = state => state.approvalsInfo
const approvalsInfoReducer = approvalsInfoSlice.reducer
export const {setApprovalsQuery, refreshApprovals} = approvalsInfoSlice.actions
export default approvalsInfoReducer