import {Button, DialogTitle, Dialog, DialogActions, IconButton} from "@mui/material";
import {useState} from "react";
import {useDispatch} from "react-redux";
import LogoutIcon from '@mui/icons-material/Logout';
import {logout} from "../../store/UserInfo";

function SignOutButton() {
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle id="signout-confirm">
                    로그아웃을 하시겠습니까?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => {
                        handleClose()
                        dispatch(logout())
                        window.location.href = `${process.env.REACT_APP_API_SERVER}/auth/logout`
                    }}>네</Button>
                    <Button onClick={handleClose} autoFocus>아니요</Button>
                </DialogActions>
            </Dialog>
            <IconButton color="inherit" onClick={handleClickOpen}>
                <LogoutIcon />
            </IconButton>
        </>
    )
}

export default SignOutButton;