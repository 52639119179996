import userNameStyles from "./UserName.module.css"
import {Link} from "react-router-dom";

export default function UserName({onClick = null, to = null, user = null, defaultLabel = "-", ...props}) {
    if(user === null) return null

    if(!user.id) {
        return (
            <span className={userNameStyles.empty} {...props}>
                {defaultLabel}
            </span>
        )
    }

    return (
        <span onClick={() => {if(onClick) onClick(user.id)}} className={userNameStyles.name} {...props}>
        {to && (<Link to={to}>{user.displayName}</Link>)}
            {!to && (<>{user.displayName}</>)}
        </span>
    )
}
