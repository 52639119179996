import {useDispatch, useSelector} from "react-redux";
import {blacklistSelector, getBlacklist} from "../store/BlacklistInfo";
import {useEffect} from "react";

export default function useBlacklist() {
    const dispatch = useDispatch()
    const blacklistInfo = useSelector(blacklistSelector)
    
    useEffect(() => {
        if(!blacklistInfo.blacklistLoaded){
            dispatch(getBlacklist())
        }
    }, [blacklistInfo, dispatch])

    return blacklistInfo.blacklist
} 