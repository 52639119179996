import {useDropzone} from "react-dropzone";
import {useEffect} from "react";
import dropzoneStyles from "./FileDropzone.module.css";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {Button} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import {bytesToSize} from "../util";

function isImage(file) {
    return file && file['type'].split('/')[0] === 'image';
}

function FilePreviewIcon({file}) {
    if (!file) return null

    if(isImage(file)) {
        return (
            <div className={dropzoneStyles.icon}>
                <img src={file.preview} alt={file.path}/>
            </div>
        )
    } else {
        return (
            <div className={dropzoneStyles.icon}>
                <FilePresentIcon sx={{width:32, height:32}}/>
            </div>
        )
    }
}

function FilePreview({file, onDelete}) {
    if(!file) return null

    const fileSize = bytesToSize(file.size)

    return (
        <div className={dropzoneStyles.thumbnail}>
            <div className={dropzoneStyles.labelArea}>
                <FilePreviewIcon file={file} />
                {file.path} <button>주소복사</button> ({fileSize})
            </div>
            <Button onClick={() => onDelete(file)} size="small" sx={{minWidth: 32}}>
                <DeleteIcon sx={{width: "20px"}}/>
            </Button>
        </div>
    )
}

export default function FileDropzone({onUpdated, uploadFiles, setUploadFiles}) {
    useEffect(() => {
        return () => {
            setUploadFiles([])
        }
    }, [setUploadFiles])

    const onDrop = (acceptedFiles) => {
        if(acceptedFiles.length > 0) {
            const newFiles = acceptedFiles.filter(file => !uploadFiles.find(f => f.path === file.path))
            newFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))

            const newList = uploadFiles.concat(newFiles)
            setUploadFiles(newList)

            if(onUpdated) {
                onUpdated(newList)
            }
        }
    }

    const onDelete = (file) => {
        const newList = uploadFiles.filter(f => file.path !== f.path)
        setUploadFiles(newList)
        if(onUpdated) {
            onUpdated(newList)
        }
    }

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    return (
        <>
            <aside className={dropzoneStyles.preview}>
                {uploadFiles.map((file, idx) => (
                    <FilePreview file={file} key={idx} onDelete={onDelete} />
                ))}
            </aside>
            {uploadFiles.length > 0 && <Button onClick={() => { setUploadFiles([]) }}>모두 삭제</Button>}
            <div {...getRootProps()} className={dropzoneStyles.dropzone}>
                <input {...getInputProps()} />
                {isDragActive ?
                    <p>여기에 파일을 드랍하세요.</p> :
                    <p>업로드할 파일을 여기로 드래그하거나 클릭해서 파일을 선택하세요.</p>
                }
                <UploadFileIcon/>
            </div>
        </>
    )
}