import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import dayjs from "dayjs";

export const vacationTypeLabel = ["일반", "경조사", "특수"]

export const vacationStatus = [
    {
        label: "결재이전",
        desc: "확인이 되지 않은 휴가입니다. 확정 이전 상태이기 때문에 휴가일수에 차감되기 이전입니다.",
        bg: "#a0a0a0",
    },
    {
        label: "결재완료",
        desc: "확인이 완료된 휴가입니다.",
        bg: "#ff4e55",
        manageLabel: "취소요청"
    },
    {
        label: "취소요청",
        desc: "관리자에게 휴가 사용 취소를 요청한 상태입니다. 확정 이전 상태이기 때문에 결재완료와 동일하게 휴가 일수에서 차감됩니다.",
        bg: "#1b798f",
    },
    {
        label: "취소됨",
        desc: "관리자가 사용 취소를 확인한 상태입니다. 휴가일수에서 차감되지 않습니다.",
        bg: "#e0e0e0"
    }
]

export const addNewVacation = createAsyncThunk("ADD_VACATION", async ({param}, {rejectWithValue}) => {
    try {
        const response = await axios.post('/api/v1/vacation', param, {withCredentials: true})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const getVacationsByUserId = createAsyncThunk("GET_VACATION_BY_USERID", async ({userId}, {rejectWithValue, requestId, getState}) => {
    try {
        const {vacationsRequestId} = getState().vacationsInfo
        if(vacationsRequestId && requestId !== vacationsRequestId) return

        const response = await axios.get(`/api/v1/vacations/${userId}`, {withCredentials: true})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const getVacation = createAsyncThunk("GET_VACTION", async({id}, {rejectWithValue}) => {
    try {
        const response = await axios.get(`/api/v1/vacation/${id}`, {withCredentials: true})
        return response.data
    } catch (e) {
        return rejectWithValue(e.response.data)
    }
})

export const deleteVacation = createAsyncThunk("DELETE_VACATION", async({id}, {rejectWithValue}) => {
    try {
        const response = await axios.delete(`/api/v1/vacation/${id}`, {withCredentials: true})
        return response.data
    } catch(e) {
        return rejectWithValue(e.response.data)
    }
})

export const vacationsInfoSlice = createSlice({
    name: "vacations",
    initialState: {
        vacations: [],
        transformed: [],
        vacationsLoaded: false,
        started: null,
        used: {},
        available: {},
        vacationsRequestId: null,
    },
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(addNewVacation.fulfilled, (state, {payload}) => {
                state.vacations.push(payload)
                state.transformed.push(transformVacationData(payload))
                sortVacations(state)
            }).addCase(getVacationsByUserId.fulfilled, (state, {payload, meta}) => {
                if(state.vacationsRequestId && state.vacationsRequestId === meta.requestId) {
                    state.vacationsRequestId = null
                    state.vacations = payload.vacations
                    state.started = payload.started
                    state.available = payload.available
                    state.used = payload.used
                    state.transformed = payload.vacations.map(vacation => transformVacationData(vacation))
                    state.vacationsLoaded = true
                }
            }).addCase(getVacationsByUserId.rejected, (state, {meta}) => {
                if(state.vacationsRequestId && state.vacationsRequestId === meta.requestId) {
                    state.vacationsRequestId = null
                    state.vacations = []
                    state.started = null
                    state.started = {}
                    state.available = {}
                    state.transformed = []
                    state.vacationsLoaded = true
                }
            })
            .addCase(getVacationsByUserId.pending, (state, {meta}) => {
                if(!state.vacationsRequestId) {
                    state.vacationsRequestId = meta.requestId
                }
            })
            .addCase(getVacation.fulfilled, (state, {payload}) => {
                state.vacations = state.vacations.filter(v => v.id !== payload.id)
                state.transformed = state.transformed.filter(v => v.id !== payload.id)

                state.vacations.push(payload)
                state.transformed.push(transformVacationData(payload))

                sortVacations(state)
            }).addCase(deleteVacation.fulfilled, (state, {payload}) => {
                state.vacations = state.vacations.filter(v => v.id !== payload.id)
                state.transformed = state.transformed.filter(v => v.id !== payload.id)

                sortVacations(state)
            })
    }
})

function sortVacations(state) {
    state.vacations.sort((a, b) => a.id - b.id)
    state.transformed.sort((a, b) => a.id - b.id)
}

export function transformVacationData(data, showName = false) {
    const ret = {}

    if(!data) return ret

    ret.id = data.id
    ret.AllDay = (data.method !== 0)
    if(data.type < 10) {
        ret.title = showName ? `${data.user.displayName} - ${vacationTypeLabel[data.type]}` : `${vacationTypeLabel[data.type]}`
    } else {
        ret.title = data.comment
    }
    ret.editable = false

    if (data.method === 0) {
        ret.start = dayjs(data.startDate).format("YYYY-MM-DD HH:mm")
        ret.end = dayjs(data.endDate).format("YYYY-MM-DD HH:mm")
    } else if(data.method === 1) {
        ret.date = dayjs(data.startDate).format("YYYY-MM-DD")
    } else if(data.method === 2) {
        ret.start = dayjs(data.startDate).format("YYYY-MM-DD")
        ret.end = dayjs(data.endDate).add(1, 'd').format("YYYY-MM-DD")
    }

    ret.backgroundColor = ret.borderColor = (data.type < 10) ? vacationStatus[data.status].bg : "#205E61"

    switch(data.type) {
        case 11:
            ret.borderColor = ret.backgroundColor = "#ff0000"
            break
        case 12:
            ret.borderColor = ret.backgroundColor = "#553c7c"
            break
        default:
            break
    }

    return ret
}

export const vacationsInfoSelector = state => state.vacationsInfo
const vacationsInfoReducer = vacationsInfoSlice.reducer
export default vacationsInfoReducer