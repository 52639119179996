import styles from "./Commits.module.css"
import {Link} from "react-router-dom";

const COLOR_CONST = 128

export function stringToRgb(inputString) {
    let hash = 0;
    for (let i = 0; i < inputString.length; i++) {
        hash = inputString.charCodeAt(i) + ((hash << 5) - hash);
    }
    const r = ((hash & 0xFF0000) >> 16) % COLOR_CONST;
    const g = ((hash & 0x00FF00) >> 8) % COLOR_CONST;
    const b = (hash & 0x0000FF) % COLOR_CONST;

    // 16진수 문자열로 변환
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export default function CommitRepo({commit, showRef = true}) {
    const color = stringToRgb(commit.repo)
    const url = `/commits?mode=repo&keyword=${encodeURIComponent(commit.repo)}&page=0`
    let branch = ""

    if(showRef && commit.ref) {
        const arr = commit.ref.split("/")
        if(branch !== "master") branch = arr[arr.length - 1]
    }

    return (
        <div className={styles.repoWrap}>
            <div style={{backgroundColor: color}} className={styles.repo}>
                <Link to={url}>
                    {commit.repo}
                </Link>
            </div>
            {showRef && branch !== "" && (
                <div className={styles.branch}>
                    {branch}
                </div>
            )}
        </div>
    )
}