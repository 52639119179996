import celebrateStyles from "./Celebrate.module.css";
import {useMemo} from "react";
import DateTimeLabel from "../common/DateTimeLabel";
import {Link} from "react-router-dom";
import UserName from "../common/UserName";
import dayjs from "dayjs";

function AnnounceNewCrew({crew}) {
    return (
        <span className={celebrateStyles.crew}>
            <UserName user={crew.user}/>님의 입사를 환영합니다! (<DateTimeLabel time={crew.started} showYear={false} showTime={false} />)
        </span>
    )
}

function AnnounceBirthday({birthInfo}) {
    return (
        <span className={celebrateStyles.birthday}>
            <UserName user={birthInfo.user} title={`${birthInfo.age}세`}/>님 생일을 축하드립니다! (<DateTimeLabel time={birthInfo.birth} showYear={false} showTime={false} />)
        </span>
    )
}

function AnnounceTaskCompleted({task}) {
    return (
        <span className={celebrateStyles.task}>
            <UserName user={task.user} />님, <Link to={`/task/${task.id}`}>{task.subject}</Link>을 <DateTimeLabel time={task.done} showTime={false} fromNow={true} parentheses={false} /> 완료!
        </span>
    )
}

export default function Celebrate({siteDataInfo}) {
    const displayCheck = useMemo(() => {
        return (siteDataInfo.newCrews && siteDataInfo.newCrews.length > 0) || (siteDataInfo.birthdays && siteDataInfo.birthdays.length > 0) || (siteDataInfo.tasks && siteDataInfo.tasks.length > 0)
    }, [siteDataInfo])

    if(!displayCheck) return null

    return (
        <div className={celebrateStyles.dashboard}>
            <div className={celebrateStyles.newCrews}>
                {siteDataInfo.newCrews.filter(crew => Math.abs(dayjs(crew.started).diff(dayjs(), 'd')) < 15).map((crew, idx) => <AnnounceNewCrew key={idx} crew={crew}/>)}
            </div>
            <div className={celebrateStyles.birthdays}>
                {siteDataInfo.birthdays.filter(birth => Math.abs(dayjs(birth.birth).diff(dayjs(), 'd')) < 15).map((birthInfo, idx) => <AnnounceBirthday key={idx} birthInfo={birthInfo}/>)}
            </div>
            <div className={celebrateStyles.tasks}>
                {siteDataInfo.tasks.map((taskInfo, idx) => <AnnounceTaskCompleted key={idx} task={taskInfo} />)}
            </div>
        </div>
    )
}