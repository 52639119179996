import {FormControlLabel, Grid, Radio, RadioGroup} from "@mui/material";
import {useState} from "react";

export default function SignaturePage() {
    const [lang, setLang] = useState('ko')
    const [phone, setPhone] = useState("false")

    return (
        <>
            <h1>이메일 서명 유틸리티</h1>
            <p>이 세팅을 바꿔도 아웃룩 설정이 자동으로 바뀌거나 하지 않습니다. 이 페이지의 내용을 바탕으로 복사 / 붙여 넣기로 일관된 서명을 제작할 수 있습니다.</p>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <RadioGroup row value={lang} onChange={(e) => {
                        setLang(e.target.value)
                    }}>
                        <h4 style={{marginRight: '1.5rem'}}>언어</h4>
                        <FormControlLabel value="ko" control={<Radio />} label="한국어" />
                        <FormControlLabel value="en" control={<Radio />} label="영어" />
                    </RadioGroup>
                    <RadioGroup row value={phone} onChange={(e) => {
                        setPhone(e.target.value)
                    }}>
                        <h4 style={{marginRight: '1.5rem'}}>전화번호</h4>
                        <FormControlLabel value="true" control={<Radio />} label="켜기" />
                        <FormControlLabel value="false" control={<Radio />} label="끄기" />
                    </RadioGroup>
                </Grid>
                <Grid item xs={12} md={6} sx={{minWidth: '420px'}}  minHeight={300}>
                    <iframe src={`/api/v1/preview/email/signature?lang=${lang}&phone=${phone}`} title="이메일 서명 미리보기" width="100%" height="100%"/>
                </Grid>
            </Grid>
        </>
    )
}