import Header from "./layout/Header";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import MainPage from "./pages/MainPage";
import NotFoundPage from "./pages/NotFoundPage";
import NonAuthorizedPage from "./pages/NonAuthorizedPage";
import {
    Box,
    Container,
    CssBaseline,
    Divider,
    IconButton, List, ListItemButton, ListItemText,
    Drawer,
    Toolbar
} from "@mui/material";
import {useState, lazy, Suspense} from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {useNavigate} from "react-router";
import useUser from "./hooks/useUser";
import UsersPage from "./pages/UsersPage";
import BlacklistPage from "./pages/BlacklistPage";
import SetupPage from "./pages/SetupPage";
import SignaturePage from "./pages/SignaturePage";
import menuList from "./utils/menu";
import PositionsPage from "./pages/PositionsPage";
import SendEmailPage from "./pages/SendEmailPage";
import ApprovalPage from "./pages/ApprovalPage";
import AlertComponent from "./components/common/AlertComponent";
import Footer from "./layout/Footer";
import Forum from "./pages/Forum";
import CertsPage from "./pages/CertsPage";
import "react-toastify/dist/ReactToastify.css"
import {ToastContainer} from "react-toastify";
import {PuffLoader} from "react-spinners";
import loadingStyles from "./loading.module.css"
import ForumNewPost from "./pages/ForumNewPost";
import WikiHistoryPage from "./pages/WikiHistoryPage";

export function Loading() {
    return (
        <div className={loadingStyles.load}>
            <PuffLoader loading={true} color="#ff4e55" />
        </div>
    )
}

function DisplayMenu({closeFunc}) {
    const userInfo = useUser()
    const navigate = useNavigate()
    const handleClick = (menu) => {
        closeFunc()
        if(menu.href) {
            window.location.href = menu.href
        } else if(menu.to){
            navigate(menu.to)
        }
    }

    let itemsBetweenDivider = 0
    const list = userInfo.logged ? menuList.logged : menuList.not_logged

    return (
        <>
            {list.map((menu, index) => {
                if(menu.required && !userInfo.roles[menu.required].v)
                    return null

                if(menu.edit_required && !userInfo.roles[menu.edit_required].e)
                    return null

                if(typeof(menu) === "string") {
                    if(itemsBetweenDivider > 0) {
                        itemsBetweenDivider = 0
                        return <Divider sx={{my: 1}} key={index}/>
                    }
                } else {
                    itemsBetweenDivider++;
                    return (
                        <ListItemButton key={menu.id} onClick={() => handleClick(menu)}>
                            <ListItemText primary={menu.label}/>
                        </ListItemButton>
                    )
                }
                return null
            })}
        </>
    )
}

const LazyJobsPage = lazy(() => import('./pages/JobsPage'))
const LazyJobPage = lazy(() => import('./pages/JobPage'))
const LazyJobPostPage = lazy(() => import('./pages/JobPostPage'))
const LazyTasksPage = lazy(() => import('./pages/TasksPage'))
const LazyTaskPage = lazy(() => import('./pages/TaskPage'))
const LazyTaskPostPage = lazy(() => import('./pages/TaskPostPage'))
const LazyAeroInvitationPage = lazy(() => import('./pages/AeroInvitationPage'))
const LazyVacationPage = lazy(() => import('./pages/VacationPage'))
const LazyVacationsHistoryPage = lazy(() => import('./pages/VacationsHistory'))
const LazyApplicantsPage = lazy(() => import('./pages/ApplicantsPage'))
const LazyApplicantPage = lazy(() => import('./pages/ApplicantPage'))
const LazyWikiPage = lazy(() => import('./pages/WikiPage'))
const LazyWikiPostPage = lazy(() => import('./pages/WikiPostPage'))
const LazyCommitsPage = lazy(() => import('./pages/CommitsPage'))
const LazyCommitPage = lazy(() => import('./pages/CommitPage'))
const LazyDiagramsPage = lazy(() => import('./pages/DiagramsPage'))
const LazyDiagramPostPage = lazy(() => import('./pages/DiagramPostPage'))
const LazyDiagramPage = lazy(() => import('./pages/DiagramPage'))

function App() {
    const [drawer, setDrawer] = useState(false)
    const userInfo = useUser()

    const closeDrawer = () => {
        setDrawer(false)
    }

    const toggleDrawer = () => {
        setDrawer(!drawer)
    }

    return (
        <BrowserRouter>
            <AlertComponent />
            <CssBaseline />
            <Box>
                <Header toggleDrawer={toggleDrawer}/>
                <Drawer open={drawer} onClose={closeDrawer}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={closeDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav" sx={{width: 250}}>
                        <DisplayMenu closeFunc={closeDrawer} />
                    </List>
                </Drawer>
                <Suspense fallback={<Loading />}>
                    <Container sx={{py:0}} maxWidth="xl">
                        {userInfo.logged &&
                            <Routes>
                                <Route path="/" element={<MainPage />}/>
                                <Route path="/tasks" element={<LazyTasksPage />}/>
                                <Route path="/task">
                                    <Route path=":taskId" element={<LazyTaskPage />} />
                                </Route>
                                <Route path="/task/post">
                                    <Route index element={<LazyTaskPostPage />} />
                                    <Route path=":taskId" element={<LazyTaskPostPage />} />
                                </Route>
                                <Route path="/jobs" element={<LazyJobsPage/>}/>
                                <Route path="/job">
                                    <Route path=":jobId" element={<LazyJobPage/>} />
                                </Route>
                                <Route path="/job/post">
                                    <Route index element={<LazyJobPostPage/>} />
                                    <Route path=":jobId" element={<LazyJobPostPage/>} />
                                </Route>
                                <Route path="/blacklist" element={<BlacklistPage />}/>
                                <Route path="/applicants" element={<LazyApplicantsPage/>}/>
                                <Route path="/applicant">
                                    <Route path=":applicantId" element={<LazyApplicantPage />} />
                                </Route>
                                <Route path="/forum">
                                    <Route path=":forumId" element={<Forum />} />
                                    <Route path=":forumId/post" element={<ForumNewPost />} />
                                </Route>
                                <Route path="/wiki">
                                    <Route path="post/*" element={<LazyWikiPostPage />} />
                                    <Route index element={<LazyWikiPage />} />
                                    <Route path="*" element={<LazyWikiPage />} />
                                </Route>
                                <Route path="/commits" element={<LazyCommitsPage />}/>
                                <Route path="/commit">
                                    <Route path=":id" element={<LazyCommitPage />} />
                                </Route>
                                <Route path="/diagrams" element={<LazyDiagramsPage />}/>
                                <Route path="/diagram/post">
                                    <Route index element={<LazyDiagramPostPage />} />
                                    <Route path=":diagramId" element={<LazyDiagramPostPage />} />
                                </Route>
                                <Route path="/diagram">
                                    <Route path=":diagramId" element={<LazyDiagramPage />} />
                                </Route>
                                <Route path="/wiki-history/*" element={<WikiHistoryPage />}/>
                                <Route path="/signature" element={<SignaturePage />} />
                                <Route path="/certs" element={<CertsPage />} />
                                <Route path="/approval" element={<ApprovalPage />} />
                                <Route path="/vacation" element={<LazyVacationPage />} />
                                <Route path="/vacations-history" element={<LazyVacationsHistoryPage />} />
                                <Route path="/users" element={<UsersPage />} />
                                <Route path="/sendmail" element={<SendEmailPage />} />
                                <Route path="/positions" element={<PositionsPage />} />
                                <Route path="/setup" element={<SetupPage />} />
                                <Route path="/aeronet" element={<LazyAeroInvitationPage />} />
                                <Route path="/404" element={<NotFoundPage />} />
                                <Route path="*" element={<NotFoundPage/>}/>
                            </Routes>
                        }
                        {!userInfo.logged &&
                            <Routes>
                                <Route path="*" element={<NonAuthorizedPage />}/>
                            </Routes>
                        }
                    </Container>
                </Suspense>
                <ToastContainer position="top-center" autoClose={3000} newestOnTop={true} hideProgressBar={false} closeOnClick={true} pauseOnFocusLoss={false} theme="colored" />
            </Box>
            <Footer />
        </BrowserRouter>
    )
}

export default App;

