import dayjs from "dayjs";

export function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export function sortComments(state) {
    state.comments.sort(function(a, b) {
        const date1 = dayjs(a.updated)
        const date2 = dayjs(b.updated)

        return date1.diff(date2)
    })
}
