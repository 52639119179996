import dayjs from "dayjs";
import {printUsed} from "../../utils/vaction";

const convertStartDate = (vacation) => {
    if(vacation) {
        if (Number(vacation.method) === 0) {
            return dayjs(vacation.startDate).format("YY/MM/DD HH:mm")
        } else {
            return dayjs(vacation.startDate).format("YY/MM/DD")
        }
    }
    return ""
}

const convertEndDate = (vacation) => {
    if(vacation) {
        if (Number(vacation.method) === 0) {
            return dayjs(vacation.endDate).format("HH:mm")
        } else if(Number(vacation.method) === 2) {
            return dayjs(vacation.endDate).format("YY/MM/DD")
        }
    }
    return ""
}

export function VacationDuration({vacation, ignoreStatus = false, ...props}) {
    const startDate = convertStartDate(vacation)
    const endDate = convertEndDate(vacation)
    const period = (vacation) ? printUsed(vacation, ignoreStatus) : ""

    if(endDate !== "") {
        return (
            <span {...props}>
                {startDate} ~ {endDate} ({period})
            </span>
        )
    } else {
        return (
            <span {...props}>
                {startDate} ({period})
            </span>
        )
    }
}